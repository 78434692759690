import { StattedPointDto } from "sandy-shared/dist/dtos/statted-point.dto";
import {ServeOutLocation} from "sandy-shared/dist/types/servce-info.type";
import {buildPointHandler} from "sandy-shared/dist/utils/statted-point-helpers";

export interface PointUiInfo {
  summary: string;
  detail: string;
  homeScore: number;
  awayScore: number;
}

export type StattedPointWithUiInfo = StattedPointDto & {uiInfo: PointUiInfo};

const getPointUiDescription = buildPointHandler<{summary: string, detail: string}>(
  serve => {
    if(serve.outLocation === ServeOutLocation.Net) {
      return {summary: 'Net Serve', detail: `Missed Serve: ${serve.outLocation}`}
    }
    if(serve.outLocation === ServeOutLocation.Fault) {
      return {summary: 'Service Fault', detail: `Service Fault`}
    }
    return {summary: 'Missed Serve', detail: `Missed Serve: ${serve.outLocation}`}
  },
  (serve) => ({summary: 'Ace', detail: `Ace: ${serve.inPlayLocation.side}, ${serve.inPlayLocation.location}`}),
  (serve) => ({summary: 'Transition', detail: 'Free Ball'}),
  (serve, attack) => ({summary: attack.outcome, detail: attack.outcome}),
  (serve, attack) => ({summary: `${attack.outcome} (${attack.attackType})`, detail: `${attack.outcome} (${attack.attackType}): ${attack.shotLocation}`}),
  (serve, attack) => ({summary: `${attack.outcome} (${attack.attackType})`, detail: `${attack.outcome} (${attack.attackType}): ${attack.outLocation}`}),
  (serve, attack) => ({summary: attack.outcome, detail: attack.outcome})
);

export const addUiInfoToPoints = (points: StattedPointDto[], homePartnershipId: string): StattedPointWithUiInfo[] => {
  const results = [] as StattedPointWithUiInfo[];
  let homeScore = 0;
  let awayScore = 0;
  for(const point of points) {
    const {summary, detail} = getPointUiDescription(point);
    if(point.winningPartnershipId === homePartnershipId) {
      homeScore++;
    } else {
      awayScore++;
    }
    const uiInfo = {summary, detail, homeScore, awayScore};
    results.push({...point, uiInfo});
  }
  return results
}
