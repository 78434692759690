import {AddStattedPointDto, StattedPointDto} from "sandy-shared/dist/dtos/statted-point.dto";
import {authenticatedFetch} from "../shared/services/http.service";

export const getAllPoints = async (): Promise<StattedPointDto[]> => {
  const result = await authenticatedFetch(
    `${process.env.REACT_APP_SANDY_API_BASE_URL}/api/v1/points`,
    { headers: { 'Content-Type': 'application/json' } },
  ).then(async response => {
    if (!response.ok) {
      throw new Error(`Failed to fetch points: ${response.statusText}`);
    }
    return await response.json() as StattedPointDto[]
  });
  return result;
}

export const getPoints = async (setId: string): Promise<StattedPointDto[]> => {
  const result = await authenticatedFetch(
    `${process.env.REACT_APP_SANDY_API_BASE_URL}/api/v1/points?setId=${setId}`,
    { headers: { 'Content-Type': 'application/json' } },
  ).then(async response => {
    if (!response.ok) {
      throw new Error(`Failed to fetch points: ${response.statusText}`);
    }
    return await response.json() as StattedPointDto[]
  });
  return result;
}

export const getPlayerPoints = async (playerId: string): Promise<StattedPointDto[]> => {
  const result = await authenticatedFetch(
    `${process.env.REACT_APP_SANDY_API_BASE_URL}/api/v1/points?playerId=${playerId}`,
    { headers: { 'Content-Type': 'application/json' } },
  ).then(async response => {
    if (!response.ok) {
      throw new Error(`Failed to fetch points: ${response.statusText}`);
    }
    return await response.json() as StattedPointDto[]
  });
  return result;
}

export async function savePoint(point: AddStattedPointDto): Promise<StattedPointDto> {
  const result = await authenticatedFetch(
    `${process.env.REACT_APP_SANDY_API_BASE_URL}/api/v1/points`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(point),
    },
  ).then(async response => {
    if (!response.ok) {
      throw new Error(`Failed to add point: ${response.statusText}`);
    }
    return await response.json() as StattedPointDto
  });;
  return result;
}

export const deletePoint = async (pointId: string): Promise<void> => {
  await authenticatedFetch(
    `${process.env.REACT_APP_SANDY_API_BASE_URL}/api/v1/points/${pointId}`,
    { method: 'DELETE', headers: { 'Content-Type': 'application/json' } },
  ).then(async response => {
    if (!response.ok) {
      throw new Error(`Failed to delete point: ${response.statusText}`);
    }
  });
}