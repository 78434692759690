// src/components/Layout.tsx
import React from 'react';
import {AppBar, Toolbar, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, CssBaseline, Box, ListItemButton, IconButton} from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import {
  Home as HomeIcon,
  SportsVolleyball as VolleyballIcon,
  Assessment as AnalysisIcon,
  Settings as SettingsIcon,
  SportsVolleyball, Home
} from '@mui/icons-material';
import {Link, Outlet, useNavigate} from 'react-router-dom';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import {getLoggedInUser, logout} from "../shared/services/auth.service";

const drawerWidth = 240;

const Layout: React.FC = () => {
  const navigate = useNavigate();
  const [navigationDrawerOpen, setNavigationDrawerOpen] = React.useState(false);

  const toggleNavigationDrawerOpen = () => {
    setNavigationDrawerOpen(!navigationDrawerOpen);
  }

  const getUserDisplayName = () => {
    const user = getLoggedInUser();
    return user.name;
  }

  const handleHomeClick = () => {
    setNavigationDrawerOpen(false);
    navigate('/');
  }

  const handleLogoutClick = () => {
    setNavigationDrawerOpen(false);
    logout();
  }

  const handlePlayerManagementClick = () => {
    setNavigationDrawerOpen(false);
    navigate('/player-management');
  }

  const handleMatchesClick = () => {
    setNavigationDrawerOpen(false);
    navigate('/matches');
  }

  const handleMatchStattingClick = () => {
    setNavigationDrawerOpen(false);
    navigate('/matches');
  }

  const handleAnalysisClick = () => {
    setNavigationDrawerOpen(false);
    navigate('/analysis');
  }

  const handleSettingsClick = () => {
    setNavigationDrawerOpen(false);
    navigate('/settings');
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
      <MuiAppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} style={{height: '64px'}}>
        <Toolbar >
          <IconButton color="inherit" aria-label="open drawer" onClick={() => toggleNavigationDrawerOpen()} edge="start" sx={{mr: 2}}>
            <MenuIcon/>
          </IconButton>
          <Box sx={{flexGrow: 0, display: {xs: 'flex'}}}>
            <IconButton onClick={() => handleHomeClick()} ><Home/></IconButton>
          </Box>
          <Box sx={{pl: 2, flexGrow: 0, display: {xs: 'none', sm: 'flex'}}}>
            <Typography variant="h6" noWrap component="div">
              {/*{toolbarContext.title}*/}
              Sandy
            </Typography>
          </Box>
          <Box sx={{pl: 2, flexGrow: 1, display: {xs: 'none', sm: 'flex'}}}>
            {/*{toolbarContext.centeredContent}*/}
          </Box>
          <Box sx={{pl: 2, flexGrow: 1, display: {xs: 'flex', sm: 'none'}}}/> {/* Spacer for xs screens when there is no centered content */}
          {/*{toolbarContext.actions && <Box sx={{pl: 2, flexGrow: 0}}>*/}
          {/*  {toolbarContext.actions.call(this, toolbarContext.toolbarData)}*/}
          {/*</Box>}*/}
          <Typography sx={{pl: 2}} variant="body1">
            {getUserDisplayName()}
          </Typography>
          <IconButton color="inherit" aria-label="settings" onClick={() => {handleSettingsClick()}}>
            <SettingsIcon/>
          </IconButton>
          <IconButton color="inherit" aria-label="logout" onClick={() => handleLogoutClick()}>
            <LogoutIcon/>
          </IconButton>
        </Toolbar>
      </MuiAppBar>
      {/*<AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>*/}
      {/*  <Toolbar>*/}
      {/*    <Typography variant="h6" noWrap component="div">*/}
      {/*      SANDY*/}
      {/*    </Typography>*/}
      {/*  </Toolbar>*/}
      {/*</AppBar>*/}
      <Box sx={{display: 'flex', width: '100%', height: 'calc(100% - 64px)'}}>
        <Drawer
          variant="persistent"
          open={navigationDrawerOpen}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: 'auto' }}>
            <List>
              <ListItemButton onClick={handleMatchStattingClick}>
                <ListItemIcon>
                  <VolleyballIcon />
                </ListItemIcon>
                <ListItemText primary="Match Statting" />
              </ListItemButton>
              <ListItemButton onClick={handlePlayerManagementClick}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Player Management" />
              </ListItemButton>
              <ListItemButton onClick={handleAnalysisClick}>
                <ListItemIcon>
                  <AnalysisIcon />
                </ListItemIcon>
                <ListItemText primary="Analysis" />
              </ListItemButton>
              <ListItemButton onClick={handleSettingsClick}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </List>
          </Box>
        </Drawer>
      </Box>
      <Box sx={{flexGrow: 1, overflow: 'auto', height: '100%', width: '100%'}}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
