import {jwtDecode, JwtPayload} from "jwt-decode";
import {AuthenticatedUserInfo} from "sandy-shared/dist/types/authenticated-user-info.type";

interface RawJwtProperties {
  email: string;
  name: string;
  user_id: number;
  tenant_id: number;
}

export const logout = () => {
  localStorage.removeItem('idToken');
  window.location.href = '/login';
}

export const getLoggedInUser = (): AuthenticatedUserInfo => {
  const token = localStorage.getItem('idToken');
  if(!token) throw new Error("No ID token found in local storage");
  const decoded = jwtDecode<JwtPayload & RawJwtProperties>(token);
  return {
    email: decoded.email,
    name: decoded.name,
    userId: decoded.user_id,
    tenantId: decoded.tenant_id
  }
}

export const getLoggedInUserOrRedirect = (): AuthenticatedUserInfo | undefined => {
  const token = localStorage.getItem('idToken');
  if(!token) {
    window.location.href = '/login';
  } else {
    const decoded = jwtDecode<JwtPayload & RawJwtProperties>(token);
    return {
      email: decoded.email,
      name: decoded.name,
      userId: decoded.user_id,
      tenantId: decoded.tenant_id
    }
  }
}

export const getLoggedInUserOrEmpty = () : AuthenticatedUserInfo | null => {
  const token = localStorage.getItem('idToken');
  if(!token) return null;
  const decoded = jwtDecode<JwtPayload & RawJwtProperties>(token);
  return {
    email: decoded.email,
    name: decoded.name,
    userId: decoded.user_id,
    tenantId: decoded.tenant_id
  }
}

export const requireAuth = () => {
  try {
    getUserToken();
  } catch (e) {
    console.error('Error in require auth', {}, e);
  }
}

export const getUserToken = () => {
  const token = localStorage.getItem('idToken');
  if (!token) {
    window.location.href = '/login';

    // navigate('/login');
  } else {
    const decoded = jwtDecode<JwtPayload>(token);
    if((decoded?.exp ?? 0) * 1000 < Date.now()) {
      window.location.href = '/login';
    }
  }
  return token;
}


