import {PlayerStatsDto} from "sandy-shared/dist/dtos/player.dto";
import {MatchPartnershipInfoDto} from "sandy-shared/dist/types/match-info.type";
import {PlayerWithSideInfo} from "sandy-shared/dist/types/player-info.type";
import {AttackLocation, AttackOutLocation, AttackSubLocation, AttackType, ShotLocation} from "sandy-shared/dist/types/attack-info.type";
import {ServeInPlayLocation} from "sandy-shared/dist/types/servce-info.type";

export interface EphemeralPointState {
  serveInPlayLocation?: ServeInPlayLocation;
  shotLocation?: ShotLocation;
  attackLocation?: AttackLocation;
  attackSubLocation?: AttackSubLocation;
  attackOutLocation?: AttackOutLocation;
  attackType?: AttackType;
  currentPointState: CurrentPointState;
}

export type PlayerStatsWithId = PlayerStatsDto & { playerId: string };
export type PartnershipWithState = Omit<MatchPartnershipInfoDto, 'playerOne' | 'playerTwo'> & { playerOne: PlayerWithSideInfo, playerTwo: PlayerWithSideInfo, score: number };

export const CurrentPointState = {
  PendingServe: 'PendingServe',
  ServeInPlay: 'ServeInPlay',
  PendingAttack: 'PendingAttack',
  AttackInPlay: 'AttackInPlay',
  AttackOutOfPlay: 'AttackOutOfPlay',
  DigTransition: 'DigTransition',
  BlockTransition: 'BlockTransition',
  FreeBallTransition: 'FreeBallTransition',
} as const
export type CurrentPointState = typeof CurrentPointState[keyof typeof CurrentPointState]