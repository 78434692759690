import {AttackLocation, AttackSubLocation, ShotLocation} from "sandy-shared/dist/types/attack-info.type";
import { CourtSide } from "sandy-shared/dist/types/court-info.type";
import {ServeInPlayLocation, ServeSideLocation} from "sandy-shared/dist/types/servce-info.type";

export const getServeInPlayLocation = (rowIndex: number, colIndex: number): ServeInPlayLocation => {
  const side = colIndex <= 1 ? CourtSide.Left : CourtSide.Right;
  if(rowIndex === 0 && colIndex % 2 === 0) return ({side, location: ServeSideLocation.FrontLeft})
  if(rowIndex === 0 && colIndex % 2 === 1) return ({side, location: ServeSideLocation.FrontRight})
  if(rowIndex === 1 && colIndex % 2 === 0) return ({side, location: ServeSideLocation.MiddleLeft})
  if(rowIndex === 1 && colIndex % 2 === 1) return ({side, location: ServeSideLocation.MiddleRight})
  if(rowIndex === 2 && colIndex % 2 === 0) return ({side, location: ServeSideLocation.BackLeft})
  if(rowIndex === 2 && colIndex % 2 === 1) return ({side, location: ServeSideLocation.BackRight})
  throw new Error('Invalid serve in play location');
}

export const getAttackLocation = (index: number): AttackLocation => {
  if(index === 0) return AttackLocation.One;
  if(index === 1) return AttackLocation.Two;
  if(index === 2) return AttackLocation.Three;
  if(index === 3) return AttackLocation.Four;
  if(index === 4) return AttackLocation.Five;
  throw new Error('Invalid attack location index');
}

export const getShotLocation = (rowIndex: number, colIndex: number): ShotLocation => {
  if(rowIndex === 0 && colIndex === 0) return ShotLocation.DeepOne;
  if(rowIndex === 0 && colIndex === 1) return ShotLocation.DeepTwo;
  if(rowIndex === 0 && colIndex === 2) return ShotLocation.DeepThree;
  if(rowIndex === 0 && colIndex === 3) return ShotLocation.DeepFour;
  if(rowIndex === 0 && colIndex === 4) return ShotLocation.DeepFive;
  if(rowIndex === 1 && colIndex === 0) return ShotLocation.MiddleOne;
  if(rowIndex === 1 && colIndex === 1) return ShotLocation.MiddleTwo;
  if(rowIndex === 1 && colIndex === 2) return ShotLocation.MiddleThree;
  if(rowIndex === 1 && colIndex === 3) return ShotLocation.MiddleFour;
  if(rowIndex === 1 && colIndex === 4) return ShotLocation.MiddleFive;
  if(rowIndex === 2 && colIndex === 0) return ShotLocation.ShallowOne;
  if(rowIndex === 2 && colIndex === 1) return ShotLocation.ShallowTwo;
  if(rowIndex === 2 && colIndex === 2) return ShotLocation.ShallowThree;
  if(rowIndex === 2 && colIndex === 3) return ShotLocation.ShallowFour;
  if(rowIndex === 2 && colIndex === 4) return ShotLocation.ShallowFive;
  throw new Error('Invalid shot location');
}
