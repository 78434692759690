import {CreationInfoType} from "../types/creation-info.type";

export interface PlayerBasicInfoDto {
  tenantId: number;
  playerId: string;
  firstName: string;
  lastName: string;
  school: string;
  number?: number;
  position?: PlayerPosition;
  height?: number;
  class?: PlayerClass
}

export interface PlayerStatsDto {
  sideout: SideoutStatsDto
  firstBallSideout: SideoutStatsDto
  serving: ServingStatsDto;
  matchesPlayed: number;
  setsPlayed: number;
}

export interface SideoutStatsDto {
  opportunities: number;
  pointsWon: number;
  kills: number;
  errors: number;
  percentage: number;
}

export interface ServingStatsDto {
  opportunities: number;
  pointsWon: number;
  aces: number;
  errors: number;
  acePercentage: number;
  serviceErrorPercentage: number;
}

export type PlayerPosition = 'Blocker' | 'Defender' | 'Both';
export const PlayerPosition = {
  Blocker: 'Blocker' as PlayerPosition,
  Defender: 'Defender' as PlayerPosition,
  Both: 'Both' as PlayerPosition,
}
export type PlayerClass = 'Freshman' | 'Sophomore' | 'Junior' | 'Senior' | 'Other';
export const PlayerClass = {
  Freshman: 'Freshman' as PlayerClass,
  Sophomore: 'Sophomore' as PlayerClass,
  Junior: 'Junior' as PlayerClass,
  Senior: 'Senior' as PlayerClass,
  Other: 'Other' as PlayerClass,
}

export type PlayerInfoDto = PlayerBasicInfoDto & PlayerStatsDto & CreationInfoType
export type AddPlayerDto = Omit<PlayerBasicInfoDto, 'playerId' | 'tenantId'>
export type UpdatePlayerDto = Partial<AddPlayerDto>

export const validateAddPlayerDto = (player: Partial<AddPlayerDto>): string | null => {
  const requiredFields = ['firstName', 'lastName', 'school'];
  const errors: string[] = [];

  for (const field of requiredFields) {
    if (player[field as keyof Partial<AddPlayerDto>] == null) {
      errors.push(`"${field}" is missing or null.`);
    }
  }

  if (errors.length > 0) {
    return `Validation failed: ${errors.join(' ')}`;
  }

  return null;
}
