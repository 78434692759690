import {Paper} from "@mui/material";

export const SettingsPage = () => {
  return (
    <div style={{display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center'}}>
      <div>
        <h1>Settings</h1>
        <p>Coming Soon</p>
      </div>
    </div>
  );
}

export default SettingsPage;