
import React from 'react';
import {Box} from '@mui/material';
import './Court.css';

import { ServeOutLocation } from "sandy-shared/dist/types/servce-info.type";
import { AttackOutLocation } from "sandy-shared/dist/types/attack-info.type";


export interface SectionAdditionalClasses {
  receivingOutOfBounds?: string;
  receivingInBounds?: string;
  attackLocationOrFree?: string;
  shotLocation?: string;
  attackingOutOfBounds?: string;
}

export interface LocationDisplaySettings {
  showAttackLocations: boolean;
  showServeReceiveLocation: boolean;
}

export interface LocationConditionalStyles {
  shotLocationSection?: (rowIndex: number, colIndex: number) => React.CSSProperties;
  attackOnLocationSection?: (attackLocationIndex: number) => React.CSSProperties;
  attackOffLocationSection?: (attackLocationIndex: number) => React.CSSProperties;
  freeBallLocationSection?: () => React.CSSProperties;
  serveReceiveSection?: (rowIndex: number, colIndex: number) => React.CSSProperties;
  attackOutOfBoundsLocation?: (location: AttackOutLocation) => React.CSSProperties;
  serveOutOfBoundLocation?: (location: ServeOutLocation) => React.CSSProperties;
}

export interface ClickHandlers {
  handleServeReceiveSectionClick?: (rowIndex: number, colIndex: number) => void;
  handleAttackOnLocationClick?: (locationIndex: number) => void;
  handleAttackOffLocationClick?: (locationIndex: number) => void;
  handleShotLocationSectionClick?: (rowIndex: number, colIndex: number) => void;
  handleAttackOutClick?: (outLocation: AttackOutLocation) => void;
  handleFreeBallClick?: () => void;
  handleOutOfBoundsServeClick?: (serveOutLocation: ServeOutLocation) => void;
}

export interface ConditionalContent {
  serveReceiveLocation?: (rowIndex: number, colIndex: number) => React.ReactNode;
  attackOnLocation?: (attackLocationIndex: number) => React.ReactNode;
  attackOffLocation?: (attackLocationIndex: number) => React.ReactNode;
  shotLocationSection?: (rowIndex: number, colIndex: number) => React.ReactNode;
  attackOutOfBoundsSection?: (outLocation: AttackOutLocation) => React.ReactNode;
}

export interface CourtProps {
  isRotated?: boolean;
  sectionAdditionalClasses?: SectionAdditionalClasses;
  locationDisplaySettings?: LocationDisplaySettings;
  locationConditionalStyles?: LocationConditionalStyles;
  clickHandlers?: ClickHandlers;
  conditionalContent?: ConditionalContent;
}

const Court = (props: CourtProps) => {
  const courtIsRotated = props.isRotated ?? false;

  const receivingOutOfBoundsAdditionalClasses = props.sectionAdditionalClasses?.receivingOutOfBounds ?? '';
  const receivingInBoundsAdditionalClasses = props.sectionAdditionalClasses?.receivingInBounds ?? '';
  const attackLocationOrFreeAdditionalClasses = props.sectionAdditionalClasses?.attackLocationOrFree ?? '';
  const shotLocationAdditionalClasses = props.sectionAdditionalClasses?.shotLocation ?? '';
  const attackingOutOfBoundsAdditionalClasses = props.sectionAdditionalClasses?.attackingOutOfBounds ?? '';

  const shotLocationSectionStyle = props.locationConditionalStyles?.shotLocationSection ?? (() => ({}));
  const attackOnLocationSectionStyle = props.locationConditionalStyles?.attackOnLocationSection ?? (() => ({}));
  const attackOffLocationSectionStyle = props.locationConditionalStyles?.attackOffLocationSection ?? (() => ({}));
  const freeBallLocationSectionStyle = props.locationConditionalStyles?.freeBallLocationSection ?? (() => ({}));
  const serveReceiveSectionStyle = props.locationConditionalStyles?.serveReceiveSection ?? (() => ({}));
  const attackOutOfBoundsLocationStyle = props.locationConditionalStyles?.attackOutOfBoundsLocation ?? (() => ({}));
  const serveOutOfBoundsLocationStyle = props.locationConditionalStyles?.serveOutOfBoundLocation ?? (() => ({}));

  const handleServeReceiveSectionClick = props.clickHandlers?.handleServeReceiveSectionClick ?? (() => {});
  const handleAttackOnLocationClick = props.clickHandlers?.handleAttackOnLocationClick ?? (() => {});
  const handleAttackOffLocationClick = props.clickHandlers?.handleAttackOffLocationClick ?? (() => {});
  const handleShotLocationSectionClick = props.clickHandlers?.handleShotLocationSectionClick ?? (() => {});
  const handleAttackOutError = props.clickHandlers?.handleAttackOutClick ?? (() => {});
  const handleFreeBallClick = props.clickHandlers?.handleFreeBallClick ?? (() => {});
  const handleOutOfBoundsServe = props.clickHandlers?.handleOutOfBoundsServeClick ?? (() => {});

  const serveReceiveLocationContent = props.conditionalContent?.serveReceiveLocation ?? (() => null);
  const attackOnLocationContent = props.conditionalContent?.attackOnLocation ?? (() => null);
  const attackOffLocationContent = props.conditionalContent?.attackOffLocation ?? (() => null);
  const shotLocationSectionContent = props.conditionalContent?.shotLocationSection ?? (() => null);
  const attackOutOfBoundsSectionContent = props.conditionalContent?.attackOutOfBoundsSection ?? (() => null);

  return (
    <Box className={`court-and-out-of-bounds ${courtIsRotated ? 'rotate-180' : ''}`}>
      <Box className={'long-out-of-bounds'} style={{borderTop: '2px solid black'}}>
        <Box
          className={`long-out-of-bounds-subsection ${attackingOutOfBoundsAdditionalClasses}`}
          onClick={() => handleAttackOutError(AttackOutLocation.DeepLeft)}
          style={{...attackOutOfBoundsLocationStyle(AttackOutLocation.DeepLeft), borderLeft: '2px solid black', borderRight: '1px solid black'}}>
          {attackOutOfBoundsSectionContent(AttackOutLocation.DeepLeft)}
        </Box>
        <Box
          className={`long-out-of-bounds-subsection ${attackingOutOfBoundsAdditionalClasses}`}
          onClick={() => handleAttackOutError(AttackOutLocation.DeepRight)}
          style={{...attackOutOfBoundsLocationStyle(AttackOutLocation.DeepRight), borderLeft: '1px solid black', borderRight: '2px solid black'}}>
          {attackOutOfBoundsSectionContent(AttackOutLocation.DeepRight)}
        </Box>
      </Box>
      <Box className={'court-and-wide-out-of-bounds'}>
        <Box className={'wide-out-of-bounds'}> {/*Left out of bounds*/}
          <Box className={`side-wide-out-of-bounds`} style={{borderBottom: '2px solid black'}}>
            <Box
              className={`side-wide-out-of-bounds-subsection ${attackingOutOfBoundsAdditionalClasses}`}
              onClick={() => handleAttackOutError(AttackOutLocation.WideLeftDeep)}
              style={{...attackOutOfBoundsLocationStyle(AttackOutLocation.WideLeftDeep), borderBottom: '1px solid black'}}>
              {attackOutOfBoundsSectionContent(AttackOutLocation.WideLeftDeep)}
            </Box>
            <Box
              className={`side-wide-out-of-bounds-subsection ${attackingOutOfBoundsAdditionalClasses}`}
              onClick={() => handleAttackOutError(AttackOutLocation.WideLeftShallow)}
              style={{...attackOutOfBoundsLocationStyle(AttackOutLocation.WideLeftShallow), borderTop: '1px solid black'}}>
              {attackOutOfBoundsSectionContent(AttackOutLocation.WideLeftShallow)}
            </Box>
          </Box>
          <Box className={'side-wide-out-of-bounds'} >
            <Box
              className={`side-wide-out-of-bounds-subsection ${receivingOutOfBoundsAdditionalClasses}`}
              onClick={() => handleOutOfBoundsServe(ServeOutLocation.WideRightShallow)}
              style={{...serveOutOfBoundsLocationStyle(ServeOutLocation.WideRightShallow), borderTop: '2px solid black', borderBottom: '1px solid black'}}>
            </Box>
            <Box
              className={`side-wide-out-of-bounds-subsection ${receivingOutOfBoundsAdditionalClasses}`}
              onClick={() => handleOutOfBoundsServe(ServeOutLocation.WideRightDeep)}
              style={{...serveOutOfBoundsLocationStyle(ServeOutLocation.WideRightDeep), borderTop: '1px solid black'}}>
            </Box>
          </Box>
        </Box>
        <Box className={'in-bounds-court'}>
          <Box className={'shot-location-grid'}>
            {[...Array(3)].map((_, rowIndex) => (
              [...Array(5)].map((_, colIndex) => (
                <Box
                  key={`${rowIndex}-${colIndex}`}
                  className={`shot-location-section ${shotLocationAdditionalClasses}`}
                  style={shotLocationSectionStyle(rowIndex, colIndex)}
                  onClick={() => handleShotLocationSectionClick(rowIndex, colIndex)}>
                  {shotLocationSectionContent(rowIndex, colIndex)}
                </Box>
              ))
            ))}
          </Box>
          {props.locationDisplaySettings?.showAttackLocations && <Box className={'attack-locations-and-free'}>
            <Box className={'on-and-off-attack-locations'}>
              {[...Array(5)].map((_, index) => (
                <Box className={'on-and-off-attack-location'} key={`on-and-off-attack-location-${index}`}>
                  <Box
                    className={`on-attack-location ${attackLocationOrFreeAdditionalClasses}`}
                    style={attackOnLocationSectionStyle(index)}
                    onClick={() => handleAttackOnLocationClick(index)}>
                    {attackOnLocationContent(index)}
                  </Box>
                  <Box
                    className={`off-attack-location ${attackLocationOrFreeAdditionalClasses}`}
                    style={attackOffLocationSectionStyle(index)}
                    onClick={() => handleAttackOffLocationClick(index)}>
                    {attackOffLocationContent(index)}
                  </Box>
                </Box>))}
            </Box>
            <Box
              className={`attack-free-ball ${attackLocationOrFreeAdditionalClasses}`}
              style={freeBallLocationSectionStyle()}
              onClick={() => handleFreeBallClick()}>
            </Box>
          </Box>}
          {props.locationDisplaySettings?.showServeReceiveLocation && <Box className={'serve-receive-grid'}>
            {[...Array(3)].map((_, rowIndex) => (
              [...Array(4)].map((_, colIndex) => (
                <Box
                  key={`${rowIndex}-${colIndex}`}
                  className={`serve-receive-section ${receivingInBoundsAdditionalClasses}`}
                  style={serveReceiveSectionStyle(rowIndex, colIndex)}
                  onClick={() => handleServeReceiveSectionClick(rowIndex, colIndex)}
                >
                  {serveReceiveLocationContent(rowIndex, colIndex)}
                </Box>
              ))
            ))}
          </Box>}
        </Box>
        <Box className={'wide-out-of-bounds'}> {/*Right out of bounds*/}
          <Box className={'side-wide-out-of-bounds'}  style={{borderBottom: '2px solid black'}}>
            <Box
              className={`side-wide-out-of-bounds-subsection ${attackingOutOfBoundsAdditionalClasses}`}
              onClick={() => handleAttackOutError(AttackOutLocation.WideRightDeep)}
              style={{...attackOutOfBoundsLocationStyle(AttackOutLocation.WideRightDeep), borderBottom: '1px solid black'}}>
              {attackOutOfBoundsSectionContent(AttackOutLocation.WideRightDeep)}
            </Box>
            <Box
              className={`side-wide-out-of-bounds-subsection ${attackingOutOfBoundsAdditionalClasses}`}
              onClick={() => handleAttackOutError(AttackOutLocation.WideRightShallow)}
              style={{...attackOutOfBoundsLocationStyle(AttackOutLocation.WideRightShallow), borderTop: '1px solid black'}}>
              {attackOutOfBoundsSectionContent(AttackOutLocation.WideRightShallow)}
            </Box>
          </Box>
          <Box className={'side-wide-out-of-bounds'}>
            <Box
              className={`side-wide-out-of-bounds-subsection ${receivingOutOfBoundsAdditionalClasses}`}
              onClick={() => handleOutOfBoundsServe(ServeOutLocation.WideLeftShallow)}
              style={{...serveOutOfBoundsLocationStyle(ServeOutLocation.WideLeftShallow), borderTop: '2px solid black', borderBottom: '1px solid black'}}>
            </Box>
            <Box
              className={`side-wide-out-of-bounds-subsection ${receivingOutOfBoundsAdditionalClasses}`}
              onClick={() => handleOutOfBoundsServe(ServeOutLocation.WideLeftDeep)}
              style={{...serveOutOfBoundsLocationStyle(ServeOutLocation.WideLeftDeep), borderTop: '1px solid black'}}>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={'long-out-of-bounds'} style={{borderBottom: '2px solid black'}}>
        <Box
          className={`long-out-of-bounds-subsection ${receivingOutOfBoundsAdditionalClasses}`}
          onClick={() => handleOutOfBoundsServe(ServeOutLocation.DeepRight)}
          style={{...serveOutOfBoundsLocationStyle(ServeOutLocation.DeepRight), borderLeft: '2px solid black', borderRight: '1px solid black'}}>
        </Box>
        <Box
          className={`long-out-of-bounds-subsection ${receivingOutOfBoundsAdditionalClasses}`}
          onClick={() => handleOutOfBoundsServe(ServeOutLocation.DeepLeft)}
          style={{...serveOutOfBoundsLocationStyle(ServeOutLocation.DeepLeft), borderLeft: '1px solid black', borderRight: '2px solid black'}}>
        </Box>
      </Box>
    </Box>
  );
};

export default Court;
