import React, {useEffect, useState} from 'react';
import {GridColDef, GridRowParams} from "@mui/x-data-grid";
import {PlayerClass, PlayerInfoDto, PlayerPosition} from "sandy-shared/src/dtos/player.dto";
import {Filter} from "../../shared/types/filter";
import {UrlFilterAwareDataGrid} from "../../shared/components/UrlFilterAwareDataGrid";
import {Box, Button, Modal} from "@mui/material";
import PlayerManagementPlayerComponent from "./PlayerManagementPlayerComponent";
import {getPlayers} from "../../services/player.service";
import {enqueueSnackbar} from "notistack";

const showAllFilter: Filter<PlayerInfoDto> = {
  displayName: 'All',
  urlParam: 'showAll',
  filterFunction: (values: PlayerInfoDto[]) => values
}
const allFilters = [showAllFilter];
const defaultFilter = showAllFilter;

const playerColumns: GridColDef[] = [
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'number', headerName: 'Number', width: 150 },
  { field: 'class', headerName: 'Class', width: 150 },
  { field: 'school', headerName: 'School', width: 200 },
  { field: 'position', headerName: 'Position', width: 150 },
  { field: 'sideOutPercentage', headerName: 'Sideout %', width: 150 },
  { field: 'firstBallSideOutPercentage', headerName: 'FB Sideout %', width: 150 },
  { field: 'acePercentage', headerName: 'Ace %', width: 150 },
  { field: 'serviceErrorPercentage', headerName: 'Service Error %', width: 150 },
  { field: 'matchesPlayed', headerName: 'Matches Played', width: 150 },
  { field: 'setsPlayed', headerName: 'Sets Played', width: 150 }
];

const playerRowBuilder = (player: PlayerInfoDto) => {
  return {
    id: player.playerId,
    name: `${player.firstName} ${player.lastName}`,
    number: player.number,
    class: player.class,
    school: player.school,
    position: player.position,
    sideOutPercentage: player.sideout.percentage,
    firstBallSideOutPercentage: player.firstBallSideout.percentage,
    acePercentage: player.serving.acePercentage,
    serviceErrorPercentage: player.serving.serviceErrorPercentage,
    matchesPlayed: player.matchesPlayed,
    setsPlayed: player.setsPlayed
  }
}

const PlayerManagementList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedPlayerId, setSelectedPlayerId] = useState<string | undefined>(undefined);
  const [players, setPlayers] = useState<PlayerInfoDto[]>([]);

  useEffect(() => {
    refreshPlayers();
  }, []);

  const refreshPlayers = () => {
    setIsLoading(true);
    getPlayers()
      .then(players => setPlayers(players))
      .catch(e => {
        console.error('Error getting players', e)
        enqueueSnackbar(`Error getting players: ${e}`, {variant: 'error', autoHideDuration: 5000})
      })
      .finally(() => setIsLoading(false));
  }

  const handleRowClick = (params: GridRowParams) => {
    setSelectedPlayerId(params.id as string);
  };

  const handleClosePlayerInfo = () => {
    setSelectedPlayerId(undefined);
  }

  const handleSavePlayerInfo = () => {
    setSelectedPlayerId(undefined);
    refreshPlayers();
  }

  const handleAddNewPlayerClick = () => {
    setSelectedPlayerId('NEW');
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: '25px'}}>
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2}}>
        <Button variant="contained" onClick={handleAddNewPlayerClick}>Add New Player</Button>
      </Box>
      <UrlFilterAwareDataGrid
        columns={playerColumns}
        rowBuilder={playerRowBuilder}
        data={players}
        onRowClick={handleRowClick}
        allFilters={allFilters}
        defaultFilters={[defaultFilter]}
        loading={isLoading}
      />
      <Modal
        open={!!selectedPlayerId}
        onClose={() => handleClosePlayerInfo()}
      >
        <PlayerManagementPlayerComponent playerId={selectedPlayerId === 'NEW' ? undefined : selectedPlayerId} onClose={() => handleClosePlayerInfo()} onSave={() => handleSavePlayerInfo()}/>
      </Modal>
    </Box>
  )
};

export default PlayerManagementList;
