import {
  Accordion, AccordionDetails, AccordionSummary,
  Autocomplete,
  Box,
  Button, Checkbox,
  FormControl, FormControlLabel, FormGroup,
  InputLabel,
  LinearProgress, MenuItem, Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography, useTheme
} from "@mui/material";
import {
  AttackLocation,
  AttackLocationInfo,
  AttackOutcome,
  AttackOutLocation,
  AttackSubLocation,
  AttackType,
  isAttackWithAttackType,
  isAttackWithOutLocation,

} from "sandy-shared/dist/types/attack-info.type";
import {
  ServeInPlayLocation,
  ServeOutLocation,
  ServeSideLocation,
  ServiceOutcome
} from "sandy-shared/dist/types/servce-info.type";
import React, {useEffect} from "react";
import './AnalysisPage.css';
import {PlayerInfoDto} from "sandy-shared/dist/dtos/player.dto";
import {getPlayers} from "../../services/player.service";
import {enqueueSnackbar} from "notistack";
import {StattedPointDto} from "sandy-shared/dist/dtos/statted-point.dto";
import {getAllPoints, getPlayerPoints, getPoints} from "../../services/point.service";
import {CourtSide} from "sandy-shared/dist/types/court-info.type";
import {getPartnerships, getPlayerPartnerships} from "../../services/partnership.service";
import DetailedStatsCard from "../../components/DetailedStatsCard";
import {
  getAttacksByShotLocation,
  getAttacksToShotLocation, getDetailedStatTotals,
  getPointsBreakdown,
  getPointsByAttackLocation,
  getPointsByServeLocation, partnershipParticipatedInPoint, playerInPartnership
} from "sandy-shared/dist/analysis/analysis-helpers";
import {getAttackLocation, getServeInPlayLocation, getShotLocation} from "../../shared/utility/shared-court-actions";
import Court, {CourtProps} from "../../components/Court";
import {PartnershipDto} from "sandy-shared/dist/dtos/partnership.dto";
import {getGradientBackgroundColor} from "./analysis-page-helpers";
import {DetailedStatTotals, emptyPointsBreakdown, PointsBreakdown} from "sandy-shared/dist/types/analysis-info.type";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {MatchInfoDto, MatchType} from "sandy-shared/dist/types/match-info.type";
import {getMatches} from "../../services/match.service";

const sectionSelectedColor = '#1565c0';
const sandActiveColor = '#c2b280';

const allMatchTypes: MatchType[] = ['Tournament', 'Scrimmage', 'Practice', 'Other'];

export interface EnrichedPointDto extends StattedPointDto {
  matchInfo: MatchInfoDto;
}

export const AnalysisPage = () => {
  const theme = useTheme();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [allPlayers, setAllPlayers] = React.useState<PlayerInfoDto[]>([]);
  const [allPartnerships, setAllPartnerships] = React.useState<PartnershipDto[]>([]);
  const [allPoints, setAllPoints] = React.useState<StattedPointDto[]>([]);
  const [allMatches, setAllMatches] = React.useState<MatchInfoDto[]>([]);
  const [schools, setSchools] = React.useState<string[]>([]);

  const [selectedSchool, setSelectedSchool] = React.useState<string | null>(null);
  const [selectedSchoolPartnerships, setSelectedSchoolPartnerships] = React.useState<PartnershipDto[]>([]);
  const [selectedSchoolPoints, setSelectedSchoolPoints] = React.useState<EnrichedPointDto[]>([]);
  const [selectedSchoolPlayers, setSelectedSchoolPlayers] = React.useState<PlayerInfoDto[]>([]);

  const [selectedPlayers, setSelectedPlayers] = React.useState<PlayerInfoDto[]>([]);
  const [selectedMatchTypes, setSelectedMatchTypes] = React.useState<MatchType[]>(allMatchTypes);

  const [selectedPoints, setSelectedPoints] = React.useState<EnrichedPointDto[]>([]);
  const [selectedPartnershipIds, setSelectedPartnershipIds] = React.useState<string[]>([]);

  const [selectedSectionsPoints, setSelectedSectionsPoints] = React.useState<StattedPointDto[]>([]);
  const [pointsByServeLocation, setPointsByServeLocation] = React.useState<{location: ServeInPlayLocation, points: StattedPointDto[]}[]>([]);
  const [pointsByAttackLocation, setPointsByAttackLocation] = React.useState<{location: AttackLocationInfo, points: StattedPointDto[]}[]>([]);
  const [analysisType, setAnalysisType] = React.useState<'AttackLocation' | 'ServeLocation'>('AttackLocation');
  const [gradientType, setGradientType] = React.useState<'Volume' | 'Efficiency' | 'Off'>('Volume');
  const [selectedAttackLocations, setSelectedAttackLocations] = React.useState<AttackLocationInfo[]>([]);
  const [selectedServeLocations, setSelectedServeLocations] = React.useState<ServeInPlayLocation[]>([]);
  const [selectedPointsBreakdown, setSelectedPointsBreakdown] = React.useState<PointsBreakdown>(emptyPointsBreakdown);
  const [detailedPlayerStats, setDetailedPlayerStats] = React.useState<DetailedStatTotals| null>(null);

  useEffect(() => {
    Promise.all([
      getAllPoints(),
      getMatches(),
      getPartnerships(),
      getPlayers()
    ]).then(([points, matches, partnerships, players]) => {
      setAllPoints(points);
      setAllMatches(matches);
      setAllPartnerships(partnerships);
      setAllPlayers(players);
      if(players.length > 0) {
        const schools = Array.from(new Set(players.map(p => p.school))).sort((a, b) => a.localeCompare(b));
        setSchools(schools);
        setSelectedSchool(schools[0]);
      }
    })
    .catch(e => {
      console.error('Error getting data', e);
      enqueueSnackbar(`Error getting data: ${e}`, {variant: 'error', autoHideDuration: 5000});
    })
    .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    const schoolPlayers = allPlayers.filter(p => p.school === selectedSchool).sort((a, b) => a.lastName.localeCompare(b.lastName));
    const partnerships = allPartnerships.filter(p => schoolPlayers.find(sp => playerInPartnership(sp.playerId, p)));
    const rawPoints = allPoints.filter(p => partnerships.find(partnership => partnershipParticipatedInPoint(partnership.partnershipId, p)));
    const points = enrichPoints(rawPoints, allMatches);
    setSelectedSchoolPlayers(schoolPlayers);
    setSelectedSchoolPartnerships(partnerships)
    setSelectedSchoolPoints(points);

    setSelectedPlayers(schoolPlayers);
    setSelectedPartnershipIds(partnerships.map(p => p.partnershipId));
    setSelectedPoints(points);
  }, [selectedSchool]);

  useEffect(() => {
    if(!selectedPlayers || !selectedMatchTypes) return;
    const playerIds = selectedPlayers.map(p => p.playerId);
    const relevantPartnerships = allPartnerships.filter(partnership => playerIds.find(playerId => playerInPartnership(playerId, partnership)));
    const partnershipIds = relevantPartnerships.map(p => p.partnershipId);
    const relevantPoints = selectedSchoolPoints.filter(point =>
      selectedMatchTypes.includes(point.matchInfo.type) &&
      partnershipIds.find(partnershipId => partnershipParticipatedInPoint(partnershipId, point)));
    setSelectedPoints(relevantPoints);
    setSelectedPartnershipIds(partnershipIds);
  }, [selectedPlayers, selectedMatchTypes]);

  useEffect(() => {
    if(!selectedPoints || !selectedPlayers) return;
    console.log(`Points related to ${selectedSchool} (${selectedPlayers.length} players): ${selectedPoints.length}`);
    const pointsByServeLocation = getPointsByServeLocation(selectedPoints, selectedPlayers.map(p => p.playerId));
    const pointsByAttackLocation = getPointsByAttackLocation(selectedPoints, selectedPlayers.map(p => p.playerId));
    setPointsByServeLocation(pointsByServeLocation);
    setPointsByAttackLocation(pointsByAttackLocation);
  }, [selectedPoints, selectedPlayers]);

  useEffect(() => {
    let newSelectedSectionsPoints: StattedPointDto[] = [];
    if(analysisType === 'ServeLocation') {
      if(selectedServeLocations.length === 0) {
        setSelectedSectionsPoints(selectedPoints); // When no sections are selected, show all points
        return;
      }
      newSelectedSectionsPoints = pointsByServeLocation
        .filter(p => !!selectedServeLocations.find(sl => p.location.location === sl.location && p.location.side === sl.side))
        .flatMap(p => p.points);
    } else if(analysisType === 'AttackLocation') {
      if(selectedAttackLocations.length === 0) {
        setSelectedSectionsPoints(selectedPoints); // When no sections are selected, show all points
        return;
      }
      newSelectedSectionsPoints = pointsByAttackLocation
        .filter(p => !!selectedAttackLocations.find(sal => p.location.attackLocation === sal.attackLocation && p.location.attackSubLocation === sal.attackSubLocation))
        .flatMap(p => p.points);
    }
    console.log(`Selected sections points: ${newSelectedSectionsPoints.length}`);
    setSelectedSectionsPoints(newSelectedSectionsPoints);
  }, [pointsByAttackLocation, selectedAttackLocations, pointsByServeLocation, selectedServeLocations, analysisType]);

  useEffect(() => {
    if(!selectedSchool || !selectedSectionsPoints) return;
    const pointsBreakdowns = getPointsBreakdown(selectedSectionsPoints, selectedPlayers.map(p => p.playerId), selectedPartnershipIds);
    setSelectedPointsBreakdown(pointsBreakdowns)
  }, [selectedSectionsPoints, selectedPlayers]);

  useEffect(() => {
    const detailedStats = getDetailedStatTotals(selectedPointsBreakdown);
    setDetailedPlayerStats(detailedStats);
  }, [selectedPointsBreakdown]);

  const enrichPoints = (points: StattedPointDto[], matches: MatchInfoDto[]): EnrichedPointDto[] => {
    return points
      .map(p => {
        const match = matches.find(m => m.sets.map(s => s.setId).includes(p.setId));
        return {...p, matchInfo: match};
      }).filter(p => !!p.matchInfo) as EnrichedPointDto[];
  }

  const serveReceiveLocationData = (rowIndex: number, colIndex: number) => {
    const {side, location} = getSideAndLocation(rowIndex, colIndex);
    const points = pointsByServeLocation.find(p => p.location.location === location && p.location.side === side)?.points ?? [];
    const pointsBreakdown = getPointsBreakdown(points, selectedPlayers.map(p => p.playerId), selectedPartnershipIds);
    const stats = getDetailedStatTotals(pointsBreakdown);
    return {
      sideoutPercentage: stats.partnership.receivePointWinPercentage,
      fbSideoutPercentage: stats.individual.fbSideoutPercentage,
      terminalKills: stats.individual.totalKills,
      terminalErrors: stats.individual.totalErrors,
      aced: stats.individual.aced,
      totalPoints: points.length,
    }
  }

  const attackLocationData = (attackLocationIndex: number, attackSubLocation: AttackSubLocation) => {
    const attackLocation = getAttackLocation(attackLocationIndex);
    const points = pointsByAttackLocation.find(p => p.location.attackLocation === attackLocation && p.location.attackSubLocation === attackSubLocation)?.points ?? [];
    const pointsBreakdown = getPointsBreakdown(points, selectedPlayers.map(p => p.playerId), selectedPartnershipIds);
    const stats = getDetailedStatTotals(pointsBreakdown);
    return {
      hittingPercentage: stats.individual.hittingPercentage,
      terminalKills: stats.individual.totalKills,
      terminalErrors: stats.individual.totalErrors,
      opportunities: stats.individual.sideoutOpportunities
    }
  }

  const shotLocationData = (rowIndex: number, colIndex: number) => {
    const shotLocation = getShotLocation(rowIndex, colIndex);
    const points = getAttacksToShotLocation(selectedSectionsPoints, shotLocation, selectedPlayers.map(p => p.playerId));
    const pointsBreakdown = getPointsBreakdown(points, selectedPlayers.map(p => p.playerId), selectedPartnershipIds);
    const stats = getDetailedStatTotals(pointsBreakdown);
    const swings = points.filter(p => p.attackInfo && isAttackWithAttackType(p.attackInfo) && p.attackInfo.attackType === AttackType.Swing);
    const shots = points.filter(p => p.attackInfo && isAttackWithAttackType(p.attackInfo) && p.attackInfo.attackType === AttackType.Shot);
    return {
      sideoutPercentage: stats.individual.sideoutPercentage,
      terminalKills: stats.individual.totalKills,
      totalPoints: stats.individual.totalPoints,
      swings: swings.length,
      shots: shots.length
    }
  }

  const attackOutOfBoundsData = (outLocation: AttackOutLocation) => {
    const points = selectedSectionsPoints.filter(p => {
      if(!p.attackInfo) return false;
      return isAttackWithOutLocation(p.attackInfo) ? p.attackInfo!.outLocation === outLocation && selectedPlayers.find(player => p.attackInfo!.attackingPlayerId == player.playerId) : false;
    });
    return {
      totalPoints: points.length
    }
  }

  const toPercentageString = (value: number) => isNaN(value) ? '0.000' : value.toFixed(3);

  const attackingOutOfBoundsAdditionalClasses = () => '';
  const receivingOutOfBoundsAdditionalClasses = () => '';
  const shotLocationAdditionalClasses = () => '';
  const attackLocationOrFreeAdditionalClasses = () => '';
  const receivingInBoundsAdditionalClasses = () => '';

  const attackOutOfBoundsLocationStyle = (location: AttackOutLocation) => ({backgroundColor: undefined})
  const shotLocationSectionStyle = (rowIndex: number, colIndex: number) => {
    const shotLocation = getShotLocation(rowIndex, colIndex);
    const pointsByAllShotLocations = getAttacksByShotLocation(selectedSectionsPoints, selectedPlayers.map(p => p.playerId));
    const pointsCount = pointsByAllShotLocations.find(p => p.location === shotLocation)?.points?.length ?? 0;
    const maxPointsCount = Math.max(...pointsByAllShotLocations.map(p => p.points.length));
    const {sideoutPercentage} = shotLocationData(rowIndex, colIndex);
    const backgroundColor = getGradientBackgroundColor(gradientType, pointsCount, maxPointsCount, sideoutPercentage, 1);
    return { backgroundColor };
  }
  const attackOnLocationSectionStyle = (attackLocationIndex: number) => {
    const attackLocation = getAttackLocation(attackLocationIndex);
    const pointsCount = pointsByAttackLocation.find(p => p.location.attackLocation === attackLocation && p.location.attackSubLocation === AttackSubLocation.On)?.points.length || 0;
    const maxPointsCount = Math.max(...pointsByAttackLocation.map(p => p.points.length));
    const {hittingPercentage} = attackLocationData(attackLocationIndex, AttackSubLocation.On);
    const gradientBackgroundColor = getGradientBackgroundColor(gradientType, pointsCount, maxPointsCount, hittingPercentage, 1);
    const isSelected = selectedAttackLocations.find(l => l.attackLocation === attackLocation && l.attackSubLocation === AttackSubLocation.On);
    return { backgroundColor: isSelected ? sectionSelectedColor : gradientBackgroundColor };
  };
  const attackOffLocationSectionStyle = (attackLocationIndex: number) => {
    const attackLocation = getAttackLocation(attackLocationIndex);
    const pointsCount = pointsByAttackLocation.find(p => p.location.attackLocation === attackLocation && p.location.attackSubLocation === AttackSubLocation.Off)?.points.length || 0;
    const maxPointsCount = Math.max(...pointsByAttackLocation.map(p => p.points.length));
    const {hittingPercentage} = attackLocationData(attackLocationIndex, AttackSubLocation.Off);
    const gradientBackgroundColor = getGradientBackgroundColor(gradientType, pointsCount, maxPointsCount, hittingPercentage, 1);
    const isSelected = selectedAttackLocations.find(l => l.attackLocation === attackLocation && l.attackSubLocation === AttackSubLocation.Off);
    return { backgroundColor: isSelected ? sectionSelectedColor : gradientBackgroundColor };
  };
  const freeBallLocationSectionStyle = () => ({backgroundColor: undefined});
  const serveReceiveSectionStyle = (rowIndex: number, colIndex: number) => {
    const serveReceiveLocation = getServeInPlayLocation(rowIndex, colIndex);
    const pointsCount = pointsByServeLocation.find(p => p.location.location === serveReceiveLocation.location && p.location.side === serveReceiveLocation.side)?.points.length || 0;
    const maxPointsCount = Math.max(...pointsByServeLocation.map(p => p.points.length));
    const {sideoutPercentage} = serveReceiveLocationData(rowIndex, colIndex);
    const gradientBackgroundColor = getGradientBackgroundColor(gradientType, pointsCount, maxPointsCount, sideoutPercentage, 1);
    const isSelected = selectedServeLocations.find(l => l.location === serveReceiveLocation.location && l.side === serveReceiveLocation.side);
    return { backgroundColor: isSelected ? sectionSelectedColor : gradientBackgroundColor };
  }

  const showAttackLocations = () => analysisType === 'AttackLocation';
  const showServeReceiveLocation = () => analysisType === 'ServeLocation';

  const getSideAndLocation = (rowIndex: number, colIndex: number): {side: CourtSide, location: ServeSideLocation} => {
    const side = colIndex <= 1 ? CourtSide.Left : CourtSide.Right;
    if(rowIndex === 0 && colIndex % 2 === 0) return ({side, location: ServeSideLocation.FrontLeft})
    if(rowIndex === 0 && colIndex % 2 === 1) return ({side, location: ServeSideLocation.FrontRight})
    if(rowIndex === 1 && colIndex % 2 === 0) return ({side, location: ServeSideLocation.MiddleLeft})
    if(rowIndex === 1 && colIndex % 2 === 1) return ({side, location: ServeSideLocation.MiddleRight})
    if(rowIndex === 2 && colIndex % 2 === 0) return ({side, location: ServeSideLocation.BackLeft})
    if(rowIndex === 2 && colIndex % 2 === 1) return ({side, location: ServeSideLocation.BackRight})
    throw new Error('Invalid serve in play location');
  }

  const serveReceiveLocationContent = (rowIndex: number, colIndex: number) => {
    const {sideoutPercentage, fbSideoutPercentage, terminalKills, terminalErrors, aced, totalPoints} = serveReceiveLocationData(rowIndex, colIndex);
    return <Box style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <Typography>{toPercentageString(sideoutPercentage)}</Typography>
      <Typography style={{fontSize: '12px'}}>{toPercentageString(fbSideoutPercentage)}</Typography>
      <Typography style={{fontSize: '12px'}}>+{terminalKills}/{-(terminalErrors + aced)}</Typography>
      <Typography style={{fontSize: '12px'}}>{totalPoints}</Typography>
    </Box>;
  }

  const attackOnLocationContent = (attackLocationIndex: number) => {
    const {hittingPercentage, terminalKills, terminalErrors, opportunities} = attackLocationData(attackLocationIndex, AttackSubLocation.On);
    return <Box style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <Typography>{toPercentageString(hittingPercentage)}</Typography>
      <Typography style={{fontSize: '12px'}}>+{terminalKills}/{-terminalErrors}</Typography>
      <Typography style={{fontSize: '12px'}}>{opportunities}</Typography>
    </Box>;
  }

  const attackOffLocationContent = (attackLocationIndex: number) => {
    const {hittingPercentage, terminalKills, terminalErrors, opportunities} = attackLocationData(attackLocationIndex, AttackSubLocation.Off);
    return <Box style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <Typography>{toPercentageString(hittingPercentage)}</Typography>
      <Typography style={{fontSize: '12px'}}>+{terminalKills}/{-terminalErrors}</Typography>
      <Typography style={{fontSize: '12px'}}>{opportunities}</Typography>
    </Box>;
  }

  const shotLocationSectionContent = (rowIndex: number, colIndex: number) => {
    const {sideoutPercentage, terminalKills, swings, shots, totalPoints} = shotLocationData(rowIndex, colIndex);
    return <Box style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <Typography>{toPercentageString(sideoutPercentage)}</Typography>
      <Typography style={{fontSize: '12px'}}>{terminalKills}/{totalPoints}</Typography>
      <Typography style={{fontSize: '12px'}}>({swings}-{shots})</Typography>
    </Box>;
  }

  const attackOutOfBoundsSectionContent = (outLocation: AttackOutLocation) => {
    const {totalPoints} = attackOutOfBoundsData(outLocation);
    return (
    <Box style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <Typography>{totalPoints}</Typography>
    </Box>);
  }

  const handleAttackOutError = (location: AttackOutLocation) => {
    console.log('Attack out error', location);
  }

  const handleOutOfBoundsServe = (location: ServeOutLocation) => {
    console.log('Out of bounds serve', location);
  }

  const handleShotLocationSectionClick = (rowIndex: number, colIndex: number) => {
    console.log('Shot location', rowIndex, colIndex);
  }

  const handleSchoolChange = (school: string) => {
    setSelectedSchool(school);
  }

  const allPlayersSelected = () => selectedPlayers.length === selectedSchoolPlayers.length;
  const handlePlayersSelectAllCheckBoxClicked = () => {
    if(selectedPlayers.length === selectedSchoolPlayers.length) {
      setSelectedPlayers([]);
    } else {
      setSelectedPlayers(selectedSchoolPlayers);
    }
  }

  const playerIsSelected = (playerId: string) => !!selectedPlayers.find(p => p.playerId === playerId)
  const handlePlayerCheckBoxClicked = (playerId: string) => {
    const isSelected = selectedPlayers.find(p => p.playerId === playerId);
    if(isSelected) {
      setSelectedPlayers(selectedPlayers.filter(p => p.playerId !== playerId));
    } else {
      setSelectedPlayers([...selectedPlayers, allPlayers.find(p => p.playerId === playerId)!]);
    }
  }

  const allMatchTypesSelected = () => selectedMatchTypes.length === allMatchTypes.length;
  const handleMatchTypesSelectAllCheckBoxClicked = () => {
    if(selectedMatchTypes.length === allMatchTypes.length) {
      setSelectedMatchTypes([]);
    } else {
      setSelectedMatchTypes(allMatchTypes);
    }
  }

  const matchTypeIsSelected = (matchType: MatchType) => selectedMatchTypes.includes(matchType);
  const handleMatchTypeCheckBoxClicked = (matchType: MatchType) => {
    const isSelected = selectedMatchTypes.includes(matchType);
    if(isSelected) {
      setSelectedMatchTypes(selectedMatchTypes.filter(mt => mt !== matchType));
    } else {
      setSelectedMatchTypes([...selectedMatchTypes, matchType]);
    }
  }


  const handleAttackOnLocationClick = (locationIndex: number) => {
    const isSelected = selectedAttackLocations.find(l => l.attackLocation === getAttackLocation(locationIndex) && l.attackSubLocation === AttackSubLocation.On);
    if(isSelected) {
      setSelectedAttackLocations(selectedAttackLocations.filter(l => l.attackLocation !== getAttackLocation(locationIndex) || l.attackSubLocation !== AttackSubLocation.On));
    } else {
      setSelectedAttackLocations([...selectedAttackLocations, {attackLocation: getAttackLocation(locationIndex), attackSubLocation: AttackSubLocation.On}]);
    }
  }

  const handleAttackOffLocationClick = (locationIndex: number) => {
    const isSelected = selectedAttackLocations.find(l => l.attackLocation === getAttackLocation(locationIndex) && l.attackSubLocation === AttackSubLocation.Off);
    if(isSelected) {
      setSelectedAttackLocations(selectedAttackLocations.filter(l => l.attackLocation !== getAttackLocation(locationIndex) || l.attackSubLocation !== AttackSubLocation.Off));
    } else {
      setSelectedAttackLocations([...selectedAttackLocations, {attackLocation: getAttackLocation(locationIndex), attackSubLocation: AttackSubLocation.Off}]);
    }
  }

  const handleFreeBallClick = () => {
    console.log('Free ball');
  }

  const handleServeReceiveSectionClick = (rowIndex: number, colIndex: number) => {
    const sideAndLocation = getSideAndLocation(rowIndex, colIndex);
    const isSelected = selectedServeLocations.find(l => l.location === sideAndLocation.location && l.side === sideAndLocation.side);
    if(isSelected) {
      setSelectedServeLocations(selectedServeLocations.filter(l => l.location !== sideAndLocation.location || l.side !== sideAndLocation.side));
    } else {
      setSelectedServeLocations([...selectedServeLocations, sideAndLocation]);
    }
  };

  const handleAnalysisTypeChange = (event: React.MouseEvent<HTMLElement>, analysisType: 'AttackLocation' | 'ServeLocation') => {
    if(analysisType === null) return;
    setAnalysisType(analysisType);
  }

  const handleGradientTypeChange = (event: React.MouseEvent<HTMLElement>, gradientType: 'Volume' | 'Efficiency' | 'Off') => {
    if(gradientType === null) return;
    setGradientType(gradientType);
  }

  const courtProps: CourtProps = {
    sectionAdditionalClasses: {
      receivingOutOfBounds: receivingOutOfBoundsAdditionalClasses(),
      receivingInBounds: receivingInBoundsAdditionalClasses(),
      attackLocationOrFree: attackLocationOrFreeAdditionalClasses(),
      shotLocation: shotLocationAdditionalClasses(),
      attackingOutOfBounds: attackingOutOfBoundsAdditionalClasses()
    },
    locationDisplaySettings: {
      showServeReceiveLocation: showServeReceiveLocation(),
      showAttackLocations: showAttackLocations(),
    },
    locationConditionalStyles: {
      shotLocationSection: shotLocationSectionStyle,
      attackOnLocationSection: attackOnLocationSectionStyle,
      attackOffLocationSection: attackOffLocationSectionStyle,
      freeBallLocationSection: freeBallLocationSectionStyle,
      serveReceiveSection: serveReceiveSectionStyle,
      attackOutOfBoundsLocation: attackOutOfBoundsLocationStyle
    },
    clickHandlers: {
      handleServeReceiveSectionClick,
      handleAttackOnLocationClick,
      handleAttackOffLocationClick,
      handleShotLocationSectionClick,
      handleAttackOutClick: handleAttackOutError,
      handleOutOfBoundsServeClick: handleOutOfBoundsServe,
      handleFreeBallClick
    },
    conditionalContent: {
      serveReceiveLocation: serveReceiveLocationContent,
      attackOnLocation: attackOnLocationContent,
      attackOffLocation: attackOffLocationContent,
      shotLocationSection: shotLocationSectionContent,
      attackOutOfBoundsSection: attackOutOfBoundsSectionContent
    }
  }

  return (
    <div className={'page-background'}>
      {isLoading && <LinearProgress />}
      <Box className={`page-content-container`}>
        <Box className={`player-and-court-container`}>
          <Box style={{display: 'flex', flexDirection: 'column', gap: '10px', width: '300px', margin: '15px'}}>
            <FormControl fullWidth>
              <Autocomplete
                options={schools}
                getOptionLabel={(school: string) =>school}
                renderInput={(params) => <TextField {...params} label="School" />}
                value={selectedSchool}
                onChange={(event, newValue) => handleSchoolChange(newValue!)}
              />
            </FormControl>
          </Box>
          <DetailedStatsCard detailedStats={detailedPlayerStats}/>
          <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '20px', width: '100%'}}>
            <Box style={{display: 'flex', flexDirection: 'column', width: '250px'}}>
              <Box
                component="fieldset"
                sx={{border: '1px solid', borderColor: theme.palette.divider, borderRadius: '8px', padding: '4px', position: 'relative', marginTop: '24px'}}
              >
                <Typography component="legend" sx={{position: 'absolute', top: '-13px', left: '16px', fontSize: '16px', backgroundColor: theme.palette.background.default}}>
                  Court Settings
                </Typography>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '15px'}}>
                  <Typography variant={'body2'} style={{fontSize: '12px'}}>Gradient</Typography>
                  <ToggleButtonGroup style={{marginLeft: '10px', marginRight: '10px'}} color="primary" value={gradientType} exclusive onChange={handleGradientTypeChange}>
                    <ToggleButton value="Volume" style={{fontSize: '12px'}}>Volume</ToggleButton>
                    <ToggleButton value="Efficiency" style={{fontSize: '12px'}}>Efficiency</ToggleButton>
                    <ToggleButton value="Off" style={{fontSize: '12px'}}>Off</ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '5px'}}>
                  <Typography variant={'body2'} style={{fontSize: '12px'}}>Locations</Typography>
                  <ToggleButtonGroup style={{marginLeft: '10px', marginRight: '10px'}} color="primary" value={analysisType} exclusive onChange={handleAnalysisTypeChange}>
                    <ToggleButton value='AttackLocation' style={{fontSize: '12px'}}>Attacks</ToggleButton>
                    <ToggleButton value='ServeLocation' style={{fontSize: '12px'}}>Receptions</ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </Box>
              <Box
                component="fieldset"
                sx={{border: '1px solid', borderColor: theme.palette.divider, borderRadius: '8px', padding: '4px', position: 'relative', marginTop: '24px', maxHeight: '490px'}}
              >
                <Typography component="legend" sx={{position: 'absolute', top: '-13px', left: '16px', fontSize: '16px', backgroundColor: theme.palette.background.default}}>
                  Filters
                </Typography>
                <Accordion style={{marginTop: '10px', marginBottom: '10px', maxHeight: '300px', overflowY: 'auto'}}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Players</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          sx={{'& .MuiFormControlLabel-label': {fontSize: '14px'}}}
                          control={<Checkbox checked={allPlayersSelected()} onChange={() => handlePlayersSelectAllCheckBoxClicked()} name="all" />}
                          label="All"
                        />
                        {selectedSchoolPlayers.map((player) => (
                          <FormControlLabel
                            sx={{'& .MuiFormControlLabel-label': {fontSize: '14px'}}}
                            key={player.playerId}
                            control={<Checkbox checked={playerIsSelected(player.playerId)} onChange={() => handlePlayerCheckBoxClicked(player.playerId)} name={player.lastName} />}
                            label={`${player.firstName.slice(0, 1)}. ${player.lastName}`}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
                <Accordion style={{marginTop: '10px', marginBottom: '10px', maxHeight: '300px', overflowY: 'auto'}}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Match Type</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          sx={{'& .MuiFormControlLabel-label': {fontSize: '14px'}}}
                          control={<Checkbox checked={allMatchTypesSelected()} onChange={() => handleMatchTypesSelectAllCheckBoxClicked()} name="all" />}
                          label="All"
                        />
                        {allMatchTypes.map((matchType) => (
                          <FormControlLabel
                            sx={{'& .MuiFormControlLabel-label': {fontSize: '14px'}}}
                            key={matchType}
                            control={<Checkbox checked={matchTypeIsSelected(matchType)} onChange={() => handleMatchTypeCheckBoxClicked(matchType)} name={matchType} />}
                            label={matchType}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
            <Box style={{width: '350px'}}>
              <Court
                isRotated={courtProps.isRotated}
                sectionAdditionalClasses={courtProps.sectionAdditionalClasses}
                locationDisplaySettings={courtProps.locationDisplaySettings}
                locationConditionalStyles={courtProps.locationConditionalStyles}
                clickHandlers={courtProps.clickHandlers}
                conditionalContent={courtProps.conditionalContent}
              ></Court>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default AnalysisPage;
