import {CourtSide} from "./court-info.type";

export const ServiceOutcome = {
  Ace: 'Ace',
  Error: 'Error',
  Received: 'Received'
} as const
export type ServiceOutcome = typeof ServiceOutcome[keyof typeof ServiceOutcome]

export const ServeSideLocation = {
  BackLeft: 'BackLeft',
  BackRight: 'BackRight',
  MiddleLeft: 'MiddleLeft',
  MiddleRight: 'MiddleRight',
  FrontLeft: 'FrontLeft',
  FrontRight: 'FrontRight'
}
export type ServeSideLocation = typeof ServeSideLocation[keyof typeof ServeSideLocation]

export const ServeOutLocation = {
  WideLeftShallow: 'WideLeftShallow',
  WideLeftDeep: 'WideLeftDeep',
  WideRightShallow: 'WideRightShallow',
  WideRightDeep: 'WideRightDeep',
  DeepLeft: 'DeepLeft',
  DeepRight: 'DeepRight',
  Net: 'Net',
  Fault: 'Fault'
} as const
export type ServeOutLocation = typeof ServeOutLocation[keyof typeof ServeOutLocation]

export interface ServeInPlayLocation {
  side: CourtSide;
  location: ServeSideLocation;
}

export type AceServe = {
  outcome: 'Ace';
  inPlayLocation: ServeInPlayLocation;
  receivingPlayerId: string;
}
export type ReceivedServe = {
  outcome: 'Received';
  inPlayLocation: ServeInPlayLocation;
  receivingPlayerId: string;
}
export type ErrorServe = {
  outcome: 'Error';
  outLocation: ServeOutLocation;
}
export type ServeInfo = AceServe | ReceivedServe | ErrorServe;

export const isErrorServe = (serve: ServeInfo): serve is ErrorServe => {
  return serve.outcome === 'Error';
}

export const isAceServe = (serve: ServeInfo): serve is AceServe => {
  return serve.outcome === 'Ace';
}

export const isInPlayServe = (serve: ServeInfo): serve is ReceivedServe => {
  return serve.outcome === 'Received';
}

