export const AttackOutcome = {
  InKill: 'InKill',
  OBToolKill: 'OBToolKill',
  Out: 'Out',
  Dug: 'Dug',
  BlockKill: 'BlockKill',
  BlockRecycle: 'BlockRecycle',
  Fault: 'Fault',
  FreeBall: 'FreeBall',
  BallInNet: 'BallInNet',
} as const
export type AttackOutcome = typeof AttackOutcome[keyof typeof AttackOutcome]

export const AttackLocation = {
  One: 'One',
  Two: 'Two',
  Three: 'Three',
  Four: 'Four',
  Five: 'Five',
} as const
export type AttackLocation = typeof AttackLocation[keyof typeof AttackLocation]

export const AttackSubLocation = {
  On: 'On',
  Off: 'Off'
} as const
export type AttackSubLocation = typeof AttackSubLocation[keyof typeof AttackSubLocation]

export const ShotLocation = {
  ShallowOne: 'ShallowOne',
  MiddleOne: 'MiddleOne',
  DeepOne: 'DeepOne',
  ShallowTwo: 'ShallowTwo',
  MiddleTwo: 'MiddleTwo',
  DeepTwo: 'DeepTwo',
  ShallowThree: 'ShallowThree',
  MiddleThree: 'MiddleThree',
  DeepThree: 'DeepThree',
  ShallowFour: 'ShallowFour',
  MiddleFour: 'MiddleFour',
  DeepFour: 'DeepFour',
  ShallowFive: 'ShallowFive',
  MiddleFive: 'MiddleFive',
  DeepFive: 'DeepFive',
  Tool: 'Tool'
} as const
export type ShotLocation = typeof ShotLocation[keyof typeof ShotLocation]

export const AttackOutLocation = {
  WideLeftShallow: 'WideLeftShallow',
  WideLeftDeep: 'WideLeftDeep',
  WideRightShallow: 'WideRightShallow',
  WideRightDeep: 'WideRightDeep',
  DeepLeft: 'DeepLeft',
  DeepRight: 'DeepRight',
  Net: 'Net'
}
export type AttackOutLocation = typeof AttackOutLocation[keyof typeof AttackOutLocation]

export const AttackType = {
  Swing: 'Swing',
  Shot: 'Shot'
} as const
export type AttackType = typeof AttackType[keyof typeof AttackType]

export interface BasicAttackInfo {
  attackingPlayerId: string;
  isOnTwo: boolean;
}

export interface AttackTypeInfo {
  attackType: AttackType;
}

export interface AttackLocationInfo {
  attackLocation: AttackLocation;
  attackSubLocation: AttackSubLocation;
}

export type AttackWithoutShotLocation = BasicAttackInfo & AttackLocationInfo & AttackTypeInfo & {
  outcome: 'BlockKill' | 'BlockRecycle' | 'OBToolKill' | 'BallInNet';
}

export type AttackWithShotLocation = BasicAttackInfo & AttackLocationInfo & AttackTypeInfo & {
  outcome: 'InKill' | 'Dug';
  shotLocation: ShotLocation;
}

export type AttackWithOutLocation = BasicAttackInfo & AttackLocationInfo & AttackTypeInfo & {
  outcome: 'Out';
  outLocation: AttackOutLocation;
}

export type AttackWithoutAttackType = BasicAttackInfo & AttackLocationInfo & {
  outcome: 'Fault';
}

export type AttackWithoutAttackTypeOrLocation = BasicAttackInfo & {
  outcome: 'FreeBall';
}

export type AttackInfo = BasicAttackInfo & (
  AttackWithoutShotLocation |
  AttackWithShotLocation |
  AttackWithOutLocation |
  AttackWithoutAttackType |
  AttackWithoutAttackTypeOrLocation)

export const isAttackWithoutShotLocation = (attackInfo: AttackInfo): attackInfo is AttackWithoutShotLocation => {
  return attackInfo.outcome === 'BlockKill' || attackInfo.outcome === 'BlockRecycle' || attackInfo.outcome === 'OBToolKill' || attackInfo.outcome === 'BallInNet';
}

export const isAttackWithShotLocation = (attackInfo: AttackInfo): attackInfo is AttackWithShotLocation => {
  return attackInfo.outcome === 'InKill' || attackInfo.outcome === 'Dug';
}

export const isAttackWithOutLocation = (attackInfo: AttackInfo): attackInfo is AttackWithOutLocation => {
  return attackInfo.outcome === 'Out';
}

export const isAttackWithoutAttackType = (attackInfo: AttackInfo): attackInfo is AttackWithoutAttackType => {
  return attackInfo.outcome === 'Fault';
}

export const isAttackWithoutAttackTypeOrLocation = (attackInfo: AttackInfo): attackInfo is AttackWithoutAttackTypeOrLocation => {
  return attackInfo.outcome === 'FreeBall';
}

export const isAttackWithAttackType = (attackInfo: AttackInfo): attackInfo is (AttackInfo & AttackTypeInfo) => {
  return (attackInfo as AttackTypeInfo).attackType !== undefined;
}
