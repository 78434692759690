import React, {ReactNode} from 'react';
import {Navigate, Route} from 'react-router-dom';
import {getUserToken} from "../shared/services/auth.service";

interface AuthGuardProps {
  children: React.ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const isAuthenticated = () => {
    return !!getUserToken();
  };

  if (!isAuthenticated()) {
    return <Navigate to="/login" replace/>;
  }

  return <>{children}</>;
};

export default AuthGuard;