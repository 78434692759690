import {CourtSide} from "./court-info.type";
import {PlayerStatsDto} from "../dtos/player.dto";

export interface PlayerInfo {
  playerId: string;
  firstName: string;
  lastName: string;
  number?: number;
}

export type PlayerWithSideInfo = PlayerInfo & { side: CourtSide };

export const createEmptyPlayerStats = (): PlayerStatsDto => ({
  serving: {
    opportunities: 0,
    pointsWon: 0,
    errors: 0,
    aces: 0,
    acePercentage: 0,
    serviceErrorPercentage: 0,
  },
  firstBallSideout: {
    opportunities: 0,
    pointsWon: 0,
    kills: 0,
    errors: 0,
    percentage: 0,
  },
  sideout: {
    opportunities: 0,
    pointsWon: 0,
    kills: 0,
    errors: 0,
    percentage: 0,
  },
  matchesPlayed: 0,
  setsPlayed: 0
});