import {AddPartnershipDto, PartnershipDto} from "sandy-shared/dist/dtos/partnership.dto";
import {authenticatedFetch} from "../shared/services/http.service";

export const getPartnerships = async (): Promise<PartnershipDto[]> => {
  const result = await authenticatedFetch(
    `${process.env.REACT_APP_SANDY_API_BASE_URL}/api/v1/partnerships`,
    { headers: { 'Content-Type': 'application/json' } },
  ).then(async response => {
    if (!response.ok) {
      throw new Error(`Failed to fetch partnerships: ${response.statusText}`);
    }
    return await response.json() as PartnershipDto[]
  });
  return result;
}

export const getPlayerPartnerships = async (playerId: string): Promise<PartnershipDto[]> => {
  const result = await authenticatedFetch(
    `${process.env.REACT_APP_SANDY_API_BASE_URL}/api/v1/partnerships?playerId=${playerId}`,
    { headers: { 'Content-Type': 'application/json' } },
  ).then(async response => {
    if (!response.ok) {
      throw new Error(`Failed to fetch partnerships: ${response.statusText}`);
    }
    return await response.json() as PartnershipDto[]
  });
  return result;
}

export async function addPartnership(partnership: AddPartnershipDto): Promise<PartnershipDto> {
  const createdPartnership = await authenticatedFetch(
    `${process.env.REACT_APP_SANDY_API_BASE_URL}/api/v1/partnerships`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(partnership)
    }).then(async response => {
    if (!response.ok) {
      throw new Error(`Failed to add partnership: ${response.statusText}`);
    }
    return await response.json() as PartnershipDto
  });
  return createdPartnership;
}