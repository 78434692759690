import {StattedPointDto} from "../dtos/statted-point.dto";

export interface IndividualPointsBreakdown {
  sideoutOpportunities: StattedPointDto[];
  successfulSideoutPoints: StattedPointDto[];
  inKills: StattedPointDto[];
  attacksDug: StattedPointDto[];
  attacksOut: StattedPointDto[];
  aces: StattedPointDto[];
  serviceErrors: StattedPointDto[],
  aced: StattedPointDto[];
  toolKills: StattedPointDto[];
  blocked: StattedPointDto[];
  recycles: StattedPointDto[];
  netSwings: StattedPointDto[];
  ownFaults: StattedPointDto[];
  oppFaults: StattedPointDto[];
  freeBalls: StattedPointDto[];
  servesWon: StattedPointDto[];
  servesLost: StattedPointDto[];
  receivesWon: StattedPointDto[];
  receivesLost: StattedPointDto[];
  serveTransitionPointsWon: StattedPointDto[];
  serveTransitionPointsLost: StattedPointDto[];
  receiveTransitionPointsWon: StattedPointDto[];
  receiveTransitionPointsLost: StattedPointDto[];
}

export interface PartnershipPointsBreakdown {
  servesWon: StattedPointDto[];
  servesLost: StattedPointDto[];
  receivesWon: StattedPointDto[];
  receivesLost: StattedPointDto[];
  serveTransitionPointsWon: StattedPointDto[];
  serveTransitionPointsLost: StattedPointDto[];
  receiveTransitionPointsWon: StattedPointDto[];
  receiveTransitionPointsLost: StattedPointDto[];
}

export interface PointsBreakdown {
  individual: IndividualPointsBreakdown;
  partnership: PartnershipPointsBreakdown;
}

export interface DetailedIndividualStats {
  fbSideoutPercentage: number;
  sideoutPercentage: number;
  hittingPercentage: number;
  sideoutOpportunities: number;
  successfulSideouts: number;
  totalKills: number;
  totalErrors: number;
  attacksDug: number;
  totalErrorsAndAced: number;
  aces: number;
  serviceErrors: number;
  aced: number;
  toolKill: number;
  recycles: number;
  attackOut: number;
  blocked: number;
  netSwings: number;
  ownFaults: number;
  oppFaults: number;
  pointWinPercentage: number;
  servePointWinPercentage: number;
  receivePointWinPercentage: number;
  serveTransitionPointWinPercentage: number;
  receiveTransitionPointWinPercentage: number
  totalPoints: number;
  totalServes: number;
  servePointsWon: number;
  totalReceives: number;
  receivePointsWon: number;
  serveTransitionPointsWon: number;
  serveTransitionPointsLost: number;
  receiveTransitionPointsWon: number;
  receiveTransitionPointsLost: number;
}

export interface DetailedPartnershipStats {
  pointWinPercentage: number;
  servePointWinPercentage: number;
  receivePointWinPercentage: number;
  serveTransitionPointWinPercentage: number;
  receiveTransitionPointWinPercentage: number
  totalPoints: number;
  totalServes: number;
  servePointsWon: number;
  totalReceives: number;
  receivePointsWon: number;
  serveTransitionPointsWon: number;
  serveTransitionPointsLost: number;
  receiveTransitionPointsWon: number;
  receiveTransitionPointsLost: number;
}

export interface DetailedStatTotals {
  individual: DetailedIndividualStats;
  partnership: DetailedPartnershipStats;
}

export const emptyIndividualPointsBreakdown: IndividualPointsBreakdown = {
  sideoutOpportunities: [],
  successfulSideoutPoints: [],
  inKills: [],
  attacksDug: [],
  attacksOut: [],
  aces: [],
  serviceErrors: [],
  aced: [],
  toolKills: [],
  blocked: [],
  recycles: [],
  netSwings: [],
  ownFaults: [],
  oppFaults: [],
  freeBalls: [],
  servesWon: [],
  servesLost: [],
  receivesWon: [],
  receivesLost: [],
  serveTransitionPointsWon: [],
  serveTransitionPointsLost: [],
  receiveTransitionPointsWon: [],
  receiveTransitionPointsLost: []
}

export const emptyPartnershipPointsBreakdown: PartnershipPointsBreakdown = {
  servesWon: [],
  servesLost: [],
  receivesWon: [],
  receivesLost: [],
  serveTransitionPointsWon: [],
  serveTransitionPointsLost: [],
  receiveTransitionPointsWon: [],
  receiveTransitionPointsLost: []
}

export const emptyPointsBreakdown: PointsBreakdown = {
  individual: emptyIndividualPointsBreakdown,
  partnership: emptyPartnershipPointsBreakdown
}