import {AceServe, ErrorServe, isAceServe, isErrorServe, ReceivedServe} from "../types/servce-info.type";
import {StattedPointDto} from "../dtos/statted-point.dto";
import {
  AttackWithoutAttackType,
  AttackWithoutAttackTypeOrLocation,
  AttackWithOutLocation,
  AttackWithoutShotLocation,
  AttackWithShotLocation,
  isAttackWithoutAttackType,
  isAttackWithoutAttackTypeOrLocation,
  isAttackWithOutLocation,
  isAttackWithoutShotLocation,
  isAttackWithShotLocation
} from "../types/attack-info.type";

export const buildPointHandler = <T>(
  onErrorServe: (serve: ErrorServe, point: StattedPointDto) => T,
  onAce: (serve: AceServe, point: StattedPointDto) => T,
  onAttackWithoutAttackTypeOrLocation: (serve: ReceivedServe, attack: AttackWithoutAttackTypeOrLocation, point: StattedPointDto) => T,
  onAttackWithoutShotLocation: (serve: ReceivedServe, attack: AttackWithoutShotLocation, point: StattedPointDto) => T,
  onAttackWithShotLocation: (serve: ReceivedServe, attack: AttackWithShotLocation, point: StattedPointDto) => T,
  onAttackWithOutLocation: (serve: ReceivedServe, attack: AttackWithOutLocation, point: StattedPointDto) => T,
  onAttackWithoutAttackType: (serve: ReceivedServe, attack: AttackWithoutAttackType, point: StattedPointDto) => T
) => (point: StattedPointDto) => {
  if(isErrorServe(point.serveInfo)) {
    return onErrorServe(point.serveInfo, point);
  }
  if(isAceServe(point.serveInfo)) {
    return onAce(point.serveInfo, point);
  }
  if(!point.attackInfo) {
    throw new Error(`Point has serve in place but no attack info: ${point}`);
  }
  if(isAttackWithoutAttackTypeOrLocation(point.attackInfo)) {
    return onAttackWithoutAttackTypeOrLocation(point.serveInfo, point.attackInfo, point);
  }
  if(isAttackWithoutShotLocation(point.attackInfo)) {
    return onAttackWithoutShotLocation(point.serveInfo, point.attackInfo, point);
  }
  if(isAttackWithShotLocation(point.attackInfo)) {
    return onAttackWithShotLocation(point.serveInfo, point.attackInfo, point);
  }
  if(isAttackWithOutLocation(point.attackInfo)) {
    return onAttackWithOutLocation(point.serveInfo, point.attackInfo, point);
  }
  if(isAttackWithoutAttackType(point.attackInfo)) {
    return onAttackWithoutAttackType(point.serveInfo, point.attackInfo, point);
  }
  throw new Error(`Point has unknown attack info subtype ${point}`);
}