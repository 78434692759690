import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';


const LoginPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log(`process.env.REACT_APP_LOGIN_CALLBACK_URL: ${process.env.REACT_APP_LOGIN_CALLBACK_URL}`);
    const redirectUri = encodeURIComponent(process.env.REACT_APP_LOGIN_CALLBACK_URL!);
    window.location.href = `https://auth.sandy.parthadae.com/login?client_id=6ikbm2fqvc1s2sntt7iet7uvmb&response_type=code&scope=email+openid+phone&redirect_uri=${redirectUri}`;
    return;
  }, [navigate]);

  return (
    <div>
      Redirecting to login...
    </div>
  );
};

export default LoginPage;