import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  FormControl,
  InputLabel, MenuItem, Select,
  SelectChangeEvent,
  Stack,
  TextField
} from "@mui/material";
import React, {ChangeEvent, useEffect, useState} from "react";

import {PlayerClass, PlayerPosition, AddPlayerDto, PlayerInfoDto, validateAddPlayerDto} from "sandy-shared/src/dtos/player.dto";
import {addPlayer, getPlayerInfo, updatePlayer} from "../../services/player.service";
import {enqueueSnackbar} from "notistack";


export interface PlayerManagementPlayerProps {
  onClose: () => void;
  onSave: (client: PlayerInfoDto) => void;
  playerId?: string | null;
}

const PlayerManagementPlayerComponent= React.forwardRef((props: PlayerManagementPlayerProps, ref) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [savedPlayer, setSavedPlayer] = useState<PlayerInfoDto | null>(null);
  const [updatedPlayer, setUpdatedPlayer] = useState<Partial<PlayerInfoDto> | PlayerInfoDto>({});
  const [isFinishing, setIsFinishing] = useState<boolean>(false);

  useEffect(() => {
    if(props.playerId) {
      getPlayerInfo(props.playerId)
        .then(player => {
          setSavedPlayer(player);
          setUpdatedPlayer(player);
        })
        .catch(e => {
          console.error('Error getting player', {playerId: props.playerId}, e);
          enqueueSnackbar(`Error getting player: ${e}`, {variant: 'error', autoHideDuration: 5000});
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, []);


  const saveAndClose = async () => {
    const validationFailureMessage = validateAddPlayerDto(updatedPlayer)
    if(validationFailureMessage) {
      enqueueSnackbar(validationFailureMessage, {variant: 'error', autoHideDuration: 5000});
      return;
    }
    const addPropertiesOnly: AddPlayerDto = {
      firstName: updatedPlayer.firstName!,
      lastName: updatedPlayer.lastName!,
      school: updatedPlayer.school!,
      position: updatedPlayer.position,
      number: updatedPlayer.number,
      class: updatedPlayer.class,
      height: updatedPlayer.height,
    }

    setIsFinishing(true);
    try {
      const returnedPlayer = savedPlayer?.playerId ? await updatePlayer(savedPlayer!.playerId, addPropertiesOnly) : await addPlayer(addPropertiesOnly);
      props.onSave(returnedPlayer);
      return returnedPlayer;
    }
    catch (error) {
      enqueueSnackbar('Failed to save player', {variant: 'error', autoHideDuration: 5000});
      console.log('Failed to save player', error);
    }
    finally {
      setIsFinishing(false);
      props.onClose();
    }
  }

  const capitalizeName = (name: string) => name.split(' ').map(n => n.charAt(0).toUpperCase() + n.slice(1)).join(' ');

  const handlePlayerFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = capitalizeName(event.target.value);
    setUpdatedPlayer(client => ({...client!, firstName: name}));
  };

  const handlePlayerLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = capitalizeName(event.target.value);
    setUpdatedPlayer(client => ({...client!, lastName: name}));
  };

  const handlePlayerNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const parsedNumber = parseInt(event.target.value);
    const number = isNaN(parsedNumber) ? undefined : parsedNumber;
    setUpdatedPlayer(client => ({...client!, number}));
  };

  const handlePlayerSchoolChange = (event: ChangeEvent<HTMLInputElement>) => {
    const school = capitalizeName(event.target.value);
    setUpdatedPlayer(client => ({...client!, school}));
  };

  const handleClassChange = (event: SelectChangeEvent) => {
    const playerClass = event.target.value === '' ? undefined : event.target.value as PlayerClass;
    setUpdatedPlayer(client => ({...client!, class: playerClass}));
  }

  const handlePositionChange = (event: SelectChangeEvent) => {
    const position = event.target.value === '' ? undefined : event.target.value as PlayerPosition;
    setUpdatedPlayer(client => ({...client!, position}));
  }


  const handleSave = async () => {
    await saveAndClose();
  }

  return (
    isLoading ? <CircularProgress/> :
      <Card sx={{ maxWidth: 600, maxHeight: '90vh', mx: 'auto', my: 4, overflowY: 'auto' }}>
        <CardContent>
          <CardHeader title={savedPlayer?.playerId ? 'Player Info' : 'Add Player  '}/>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <TextField
                label="First Name"
                variant="outlined"
                value={updatedPlayer?.firstName ?? ''}
                onChange={handlePlayerFirstNameChange}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Last Name"
                variant="outlined"
                value={updatedPlayer?.lastName ?? ''}
                onChange={handlePlayerLastNameChange}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Number"
                variant="outlined"
                value={updatedPlayer?.number ?? ''}
                onChange={handlePlayerNumberChange}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="School"
                variant="outlined"
                value={updatedPlayer?.school ?? ''}
                onChange={handlePlayerSchoolChange}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Class</InputLabel>
              <Select
                value={updatedPlayer?.class  ?? ''}
                label="Class"
                onChange={handleClassChange}
              >
                {[...Object.values(PlayerClass)].map((playerClass) =>
                  <MenuItem value={playerClass} key={playerClass}>{playerClass}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Position</InputLabel>
              <Select
                value={updatedPlayer?.position  ?? ''}
                label="Position"
                onChange={handlePositionChange}
              >
                {[...Object.values(PlayerPosition)].map((position) =>
                  <MenuItem value={position} key={position}>{position}</MenuItem>)}
              </Select>
            </FormControl>
            <Button onClick={handleSave} variant="contained" component="label">
              {isFinishing ? <CircularProgress/> : 'Save'}
            </Button>
          </Stack>
        </CardContent>
      </Card>
  )
});

export default PlayerManagementPlayerComponent;