import React from "react";
import {Box, Card, CardContent, CardHeader, Divider, Grid, Typography} from "@mui/material";
import {PlayerInfo} from "sandy-shared/dist/types/player-info.type";
import {DetailedStatTotals} from "sandy-shared/dist/types/analysis-info.type";

export interface DetailedStatsProps {
  detailedStats: DetailedStatTotals | null;
}

const DetailedStatsCard: React.FC<DetailedStatsProps> = ({detailedStats}) => {
  const dugRecycled = (detailedStats?.individual?.sideoutOpportunities ?? 0) - (detailedStats?.individual?.totalKills ?? 0) - (detailedStats?.individual?.totalErrorsAndAced ?? 0);

  return (
    <div style={{paddingLeft: '25px', paddingRight: '25px', paddingTop: '10px', paddingBottom: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', textAlign: 'center'}}>
      <Card sx={{ minWidth: 300, borderRadius: '10px' }}>
        <CardContent style={{padding: '15px', width: '600px'}}>
          <Grid container spacing={1} >
            <Grid item xs={3}>
              <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Typography variant="caption" fontSize={16} color="text.secondary">Sideout %</Typography>
                <Typography variant="h4" component="div">{detailedStats?.individual?.sideoutPercentage?.toFixed(3) ?? 'NA'}</Typography>
                <Typography variant="caption" fontSize={14} color="text.secondary">({detailedStats?.individual?.successfulSideouts ?? 0} / {(detailedStats?.individual?.sideoutOpportunities ?? 0)})</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Typography variant="caption" fontSize={16} color="text.secondary">FB Sideout %</Typography>
                <Typography variant="h4" component="div">{detailedStats?.individual?.fbSideoutPercentage?.toFixed(3) ?? 'NA'}</Typography>
                <Typography variant="caption" fontSize={14} color="text.secondary">({detailedStats?.individual?.totalKills ?? 0} / {(detailedStats?.individual?.sideoutOpportunities ?? 0)})</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Typography variant="caption" fontSize={11} sx={{marginTop: '4.075px', marginBottom: '4.075px'}} color="text.secondary">Serve Trans. Win %</Typography>
                <Typography variant="h4" component="div">{detailedStats?.individual?.serveTransitionPointWinPercentage?.toFixed(3) ?? 'NA'}</Typography>
                <Typography variant="caption" fontSize={14} color="text.secondary"> ({detailedStats?.individual?.serveTransitionPointsWon ?? 0} / {(detailedStats?.individual?.serveTransitionPointsWon ?? 0) + (detailedStats?.individual?.serveTransitionPointsLost ?? 0)})</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Typography variant="caption" fontSize={11} sx={{marginTop: '4.075px', marginBottom: '4.075px'}} color="text.secondary">Receive Trans. Win %</Typography>
                <Typography variant="h4" component="div">{detailedStats?.individual?.receiveTransitionPointWinPercentage?.toFixed(3) ?? 'NA'}</Typography>
                <Typography variant="caption" fontSize={14} color="text.secondary">({detailedStats?.individual?.receiveTransitionPointsWon ?? 0} / {(detailedStats?.individual?.receiveTransitionPointsWon ?? 0) + (detailedStats?.individual?.receiveTransitionPointsLost ?? 0)})</Typography>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="caption" fontSize={16} sx={{ mb: 1.5 }} color="text.secondary">Opportunities</Typography>
              <Typography variant="h4" component="div">{detailedStats?.individual?.sideoutOpportunities ?? 'NA'}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" fontSize={16} sx={{ mb: 1.5 }} color="text.secondary">Kills</Typography>
              <Typography variant="h4" component="div">{detailedStats?.individual?.totalKills ?? 'NA'}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" fontSize={16} sx={{ mb: 1.5 }} color="text.secondary">Errors + Aced</Typography>
              <Typography variant="h4" component="div">{detailedStats?.individual?.totalErrorsAndAced ?? 'NA'}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" fontSize={16} sx={{ mb: 1.5 }} color="text.secondary">Dug/Recycled</Typography>
              <Typography variant="h4" component="div">{dugRecycled === 0 ? 'NA' : dugRecycled}</Typography>
            </Grid>
          </Grid>
          <Divider/>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography variant="caption" fontSize={13} sx={{ mb: 1.5 }} color="text.secondary">Aces</Typography>
              <Typography variant="h6" component="div">{detailedStats?.individual?.aces ?? 'NA'}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" fontSize={13} sx={{ mb: 1.5 }} color="text.secondary">Tool Kill</Typography>
              <Typography variant="h6" component="div">{detailedStats?.individual?.toolKill ?? 'NA'}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" fontSize={13} sx={{ mb: 1.5 }} color="text.secondary">Opp Faults</Typography>
              <Typography variant="h6" component="div">{detailedStats?.individual?.oppFaults ?? 'NA'}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" fontSize={13} sx={{ mb: 1.5 }} color="text.secondary">Recycles</Typography>
              <Typography variant="h6" component="div">{detailedStats?.individual?.recycles ?? 'NA'}</Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="caption" fontSize={13} sx={{ mb: 1.5 }} color="text.secondary">Aced</Typography>
              <Typography variant="h6" component="div">{detailedStats?.individual?.aced ?? 'NA'}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" fontSize={13} sx={{ mb: 1.5 }} color="text.secondary">Blocked</Typography>
              <Typography variant="h6" component="div">{detailedStats?.individual?.blocked ?? 'NA'}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" fontSize={13} sx={{ mb: 1.5 }} color="text.secondary">Net Swings</Typography>
              <Typography variant="h6" component="div">{detailedStats?.individual?.netSwings ?? 'NA'}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" fontSize={13} sx={{ mb: 1.5 }} color="text.secondary">Own Faults</Typography>
              <Typography variant="h6" component="div">{detailedStats?.individual?.ownFaults ?? 'NA'}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default DetailedStatsCard;
