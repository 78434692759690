const calculateGradientColor = (value: number, maxValue: number): string => {
  const maxColorValue = 128;
  const minColorValue = 15;
  const minAlpha = 0.1;
  const ratio = (value / maxValue);
  const alpha = Math.abs(ratio) * (1 - minAlpha) + minAlpha
  const colorValue = minColorValue + Math.min(maxColorValue, Math.floor(maxColorValue * Math.abs(ratio)));
  if(ratio > 0) {
    return `rgba(0, ${colorValue}, 0, ${alpha})`;
  } else {
    return `rgba(${colorValue}, 0, 0, ${alpha})`;
  }
};

export const getGradientBackgroundColor = (gradientType: 'Volume' | 'Efficiency' | 'Off', volumeValue: number, volumeValueMax: number, efficiencyValue: number, efficiencyValueMax: number): string | undefined => {
  switch (gradientType) {
    case 'Volume':
      return calculateGradientColor(volumeValue, volumeValueMax);
    case 'Efficiency':
      return calculateGradientColor(efficiencyValue, efficiencyValueMax);
    case 'Off':
      return undefined;
  }
}

