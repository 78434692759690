import {UpdatePlayerDto, PlayerInfoDto, AddPlayerDto} from "sandy-shared/src/dtos/player.dto";
import {authenticatedFetch} from "../shared/services/http.service";

export const getPlayers = async (): Promise<PlayerInfoDto[]> => {
  const result = await authenticatedFetch(
    `${process.env.REACT_APP_SANDY_API_BASE_URL}/api/v1/players`,
    { headers: { 'Content-Type': 'application/json' } },
  ).then(async response => {
    if (!response.ok) {
      throw new Error(`Failed to fetch players: ${response.statusText}`);
    }
    return await response.json() as PlayerInfoDto[]
  });
  return result;
}

export const getPlayerInfo = async (playerId: string): Promise<PlayerInfoDto> => {
  return getPlayers().then(players => players.find(player => player.playerId === playerId)!);
}

export async function addPlayer(player: AddPlayerDto): Promise<PlayerInfoDto> {
  const createdPlayer = await authenticatedFetch(
    `${process.env.REACT_APP_SANDY_API_BASE_URL}/api/v1/players`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(player)
    }).then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to add player: ${response.statusText}`);
      }
      return await response.json() as PlayerInfoDto
    });
  return createdPlayer;
}

export async function updatePlayer(playerId: string, player: UpdatePlayerDto): Promise<PlayerInfoDto> {
  const updatedPlayer = await authenticatedFetch(
    `${process.env.REACT_APP_SANDY_API_BASE_URL}/api/v1/players/${playerId}`,
    {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(player)
    }).then(async response => {
      if (!response.ok) {
          throw new Error(`Failed to update player: ${response.statusText}`);
      }
      return await response.json() as PlayerInfoDto
    });
  return updatedPlayer;
}
