export const authenticatedFetch = async (url: string, options: RequestInit): Promise<Response> => {
  const token = localStorage.getItem('idToken');
  if (!token) {
    // Redirect to the login page if no token is found
    window.location.href = '/login';
    return Promise.reject(new Error('Redirecting to login due to missing token'));
  }
  options.headers = {
    ...options.headers,
    Authorization: `Bearer ${token}`
  }
  return fetch(url, options);
}