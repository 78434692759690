import React, {useEffect, useState} from 'react';
import {GridColDef, GridRowParams} from "@mui/x-data-grid";
import {MatchInfoDto} from "sandy-shared/src/types/match-info.type";
import {Filter} from "../../shared/types/filter";
import {UrlFilterAwareDataGrid} from "../../shared/components/UrlFilterAwareDataGrid";
import {Box, Button, Modal} from "@mui/material";
import {getPlayers} from "../../services/player.service";
import {enqueueSnackbar} from "notistack";
import {getMatches} from "../../services/match.service";
import MatchInfoComponent from "./MatchInfoComponent";
import {useNavigate} from "react-router-dom";

const showAllFilter: Filter<MatchInfoDto> = {
  displayName: 'All',
  urlParam: 'showAll',
  filterFunction: (values: MatchInfoDto[]) => values
}
const allFilters = [showAllFilter];
const defaultFilter = showAllFilter;

const matchColumns: GridColDef[] = [
  { field: 'homePartnership', headerName: 'Home Partnership', width: 200 },
  { field: 'awayPartnership', headerName: 'Away Partnership', width: 200 },
  { field: 'scores', headerName: 'Scores', width: 250 },
  { field: 'type', headerName: 'Type', width: 100 },
  { field: 'status', headerName: 'Status', width: 100 },
  { field: 'date', headerName: 'Date', width: 250 }
];

const matchRowBuilder = (match: MatchInfoDto) => {
  const scores = match.sets.map(set => `${set.homeScore}-${set.awayScore}`).join(', ');
  return {
    id: match.matchId,
    homePartnership: `${match.homePartnership.playerOne.lastName}/${match.homePartnership.playerTwo.lastName}`,
    awayPartnership: `${match.awayPartnership.playerOne.lastName}/${match.awayPartnership.playerTwo.lastName}`,
    scores,
    type: match.type,
    status: match.status,
    date: match.date
  }
}

const MatchesListPage: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedMatchId, setSelectedMatchId] = useState<string | undefined>(undefined);
  const [matches, setMatches] = useState<MatchInfoDto[]>([]);

  useEffect(() => {
    refreshMatches();
  }, []);

  const refreshMatches = () => {
    setIsLoading(true);
    getMatches()
      .then(matches => setMatches(matches))
      .catch(e => {
        console.error('Error getting matches', e)
        enqueueSnackbar(`Error getting matches: ${e}`, {variant: 'error', autoHideDuration: 5000})
      })
      .finally(() => setIsLoading(false));
  }

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/match-statting/${params.id}`);
  };

  const handleCloseMatchInfo = () => {
    setSelectedMatchId(undefined);
  }

  const handleSaveMatchInfo = () => {
    setSelectedMatchId(undefined);
    refreshMatches();
  }

  const handleAddNewMatchClick = () => {
    setSelectedMatchId('NEW');
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: '25px'}}>
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2}}>
        <Button variant="contained" onClick={handleAddNewMatchClick}>Create New Match</Button>
      </Box>
      <UrlFilterAwareDataGrid
        columns={matchColumns}
        rowBuilder={matchRowBuilder}
        data={matches}
        onRowClick={handleRowClick}
        allFilters={allFilters}
        defaultFilters={[defaultFilter]}
        loading={isLoading}
      />
      <Modal
        open={!!selectedMatchId}
        onClose={() => handleCloseMatchInfo()}
      >
        <MatchInfoComponent matchId={selectedMatchId === 'NEW' ? undefined : selectedMatchId} onClose={() => handleCloseMatchInfo()} onSave={() => handleSaveMatchInfo()}/>
      </Modal>
    </Box>
  )
};

export default MatchesListPage;
