import {
  Autocomplete, AutocompleteRenderInputParams, Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {AddMatchDto, MatchInfoDto, MatchPartnershipInfoDto, validateAddMatchDto} from "sandy-shared/dist/types/match-info.type";
import dayjs from "dayjs";
import {addPlayer, getPlayerInfo, getPlayers, updatePlayer} from "../../services/player.service";
import {enqueueSnackbar} from "notistack";
import {addMatch, getMatch} from "../../services/match.service";
import {AddPlayerDto, PlayerInfoDto, validateAddPlayerDto} from "sandy-shared/dist/dtos/player.dto";
import {addPartnership, getPartnerships} from "../../services/partnership.service";
import {PartnershipDto, PartnershipPlayerDto} from "sandy-shared/dist/dtos/partnership.dto";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers"; // Update the import to match your structure

export interface MatchInfoComponentProps {
  matchId?: string;
  onClose: () => void;
  onSave: (match: MatchInfoDto) => void;
}

const NEW_PARTNERSHIP = 'NEW_PARTNERSHIP';

const MatchInfoComponent = React.forwardRef((props: MatchInfoComponentProps, ref) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [savedMatch, setSavedMatch] = useState<MatchInfoDto | null>(null);
  const [updatedMatch, setUpdatedMatch] = useState<Partial<MatchInfoDto> | MatchInfoDto>({});
  const [partnerships, setPartnerships] = useState<PartnershipDto[]>([]);
  const [players, setPlayers] = useState<PlayerInfoDto[]>([]);
  const [isFinishing, setIsFinishing] = useState<boolean>(false);
  const [newHomePlayerOne, setNewHomePlayerOne] = useState<PlayerInfoDto | null>(null);
  const [newHomePlayerTwo, setNewHomePlayerTwo] = useState<PlayerInfoDto | null>(null);
  const [newAwayPlayerOne, setNewAwayPlayerOne] = useState<PlayerInfoDto | null>(null);
  const [newAwayPlayerTwo, setNewAwayPlayerTwo] = useState<PlayerInfoDto | null>(null);

  const createNewHomePartnership = !updatedMatch.homePartnership;
  const createNewAwayPartnership = !updatedMatch.awayPartnership;

  const availableHomePartnerships: (MatchPartnershipInfoDto | 'NEW_PARTNERSHIP')[] = [NEW_PARTNERSHIP, ...partnerships.filter(partnership => updatedMatch.awayPartnership?.partnershipId !== partnership.partnershipId)];
  const availableAwayPartnerships: (MatchPartnershipInfoDto | 'NEW_PARTNERSHIP')[] = [NEW_PARTNERSHIP, ...partnerships.filter(partnership => updatedMatch.homePartnership?.partnershipId !== partnership.partnershipId)];

  const unselectedPlayers = players.filter(player => {
    const inHomePartnership = updatedMatch.homePartnership?.playerOne.playerId === player.playerId || updatedMatch.homePartnership?.playerTwo.playerId === player.playerId;
    const inAwayPartnership = updatedMatch.awayPartnership?.playerOne.playerId === player.playerId || updatedMatch.awayPartnership?.playerTwo.playerId === player.playerId;
    const newHomePlayer = newHomePlayerOne?.playerId === player.playerId || newHomePlayerTwo?.playerId === player.playerId;
    const newAwayPlayer = newAwayPlayerOne?.playerId === player.playerId || newAwayPlayerTwo?.playerId === player.playerId;
    return !inHomePartnership && !inAwayPartnership && !newHomePlayer && !newAwayPlayer;
  });

  const availablePlayersAndSelf = (self: PlayerInfoDto | null, newPartnerId?: string | undefined) => {
    const newPartnersOtherPartnerIds = partnerships
      .filter(partnership => partnership.playerOne.playerId === newPartnerId || partnership.playerTwo.playerId === self?.playerId)
      .map(partnership => partnership.playerOne.playerId === newPartnerId ? partnership.playerTwo.playerId : partnership.playerOne.playerId);
    const filteredAvailablePlayers = unselectedPlayers.filter(player => !newPartnersOtherPartnerIds.includes(player.playerId));
    return !self ? filteredAvailablePlayers : [self, ...filteredAvailablePlayers];
  }

  useEffect(() => {
    if(props.matchId) {
      getMatch(props.matchId)
        .then(player => {
          setSavedMatch(player);
          setUpdatedMatch(player);
        })
        .catch(e => {
          console.error('Error getting match', {matchId: props.matchId}, e);
          enqueueSnackbar(`Error getting match: ${e}`, {variant: 'error', autoHideDuration: 5000});
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
    getPartnerships()
      .then(partnerships => setPartnerships(partnerships))
      .catch(e => {
        console.error('Error getting partnerships', e);
        enqueueSnackbar(`Error getting partnerships: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
    getPlayers()
      .then(players => setPlayers(players))
      .catch(e => {
        console.error('Error getting players', e);
        enqueueSnackbar(`Error getting players: ${e}`, {variant: 'error', autoHideDuration: 5000});
      })
  }, []);

  useEffect(() => {
    console.log('updatedMatch.homePartnershipId', updatedMatch.homePartnership?.partnershipId);
  }, [updatedMatch.homePartnership]);

  useEffect(() => {
    console.log('updatedMatch.awayPartnershipId', updatedMatch.awayPartnership?.partnershipId);
  }, [updatedMatch.awayPartnership]);

  const handleTypeChange = (event: SelectChangeEvent) => {
    setUpdatedMatch(prev => ({ ...prev, type: event.target.value as MatchInfoDto['type'] }));
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setUpdatedMatch(prev => ({ ...prev, status: event.target.value as MatchInfoDto['status'] }));
  };

  const handleDateChange = (time: dayjs.Dayjs | null) => {
    setUpdatedMatch(prev => ({ ...prev, date: time?.toISOString() ?? '' }));
  };

  const handlePartnershipChange = (isHome: boolean, partnership: (MatchPartnershipInfoDto | 'NEW_PARTNERSHIP' | undefined)) => {
    const safePartnership = partnership ?? NEW_PARTNERSHIP;
    const matchPartnershipInfo: MatchPartnershipInfoDto | undefined = safePartnership === NEW_PARTNERSHIP ? undefined : {
      partnershipId: safePartnership.partnershipId,
      playerOne: safePartnership.playerOne,
      playerTwo: safePartnership.playerTwo
    }
    if (isHome) {
      setUpdatedMatch(prev => ({ ...prev, homePartnership: matchPartnershipInfo }));
    } else {
      setUpdatedMatch(prev => ({ ...prev, awayPartnership: matchPartnershipInfo }));
    }
  };

  const matchPartnershipInfoOnly = (partnership : PartnershipDto) : MatchPartnershipInfoDto => ({
    partnershipId: partnership.partnershipId,
    playerOne: partnership.playerOne,
    playerTwo: partnership.playerTwo
  });

  const saveAndClose = async () => {
    let homePartnership: MatchPartnershipInfoDto;
    console.log('updatedMatch.homePartnership', updatedMatch.homePartnership);
    if((updatedMatch.homePartnership ?? NEW_PARTNERSHIP) === NEW_PARTNERSHIP) {
      if(!newHomePlayerOne || !newHomePlayerTwo) {
        enqueueSnackbar('Please select two players for the home partnership', {variant: 'error', autoHideDuration: 5000});
        return;
      }
      const addedHomePartnership = await addPartnership({
        playerOneId: newHomePlayerOne.playerId,
        playerTwoId: newHomePlayerTwo.playerId
      }).catch(e => {
        console.error('Failed to add partnership', e);
        enqueueSnackbar('Failed to add partnership', {variant: 'error', autoHideDuration: 5000});
        return null;
      });
      if(!addedHomePartnership) return;
      homePartnership = matchPartnershipInfoOnly(addedHomePartnership);
    } else {
      homePartnership = updatedMatch.homePartnership!;
    }

    let awayPartnership: MatchPartnershipInfoDto;
    if((updatedMatch.awayPartnership ?? NEW_PARTNERSHIP) === NEW_PARTNERSHIP) {
      if(!newAwayPlayerOne || !newAwayPlayerTwo) {
        enqueueSnackbar('Please select two players for the away partnership', {variant: 'error', autoHideDuration: 5000});
        return;
      }
      const addedAwayPartnership = await addPartnership({
        playerOneId: newAwayPlayerOne.playerId,
        playerTwoId: newAwayPlayerTwo.playerId
      }).catch(e => {
        console.error('Failed to add partnership', e);
        enqueueSnackbar('Failed to add partnership', {variant: 'error', autoHideDuration: 5000});
        return null;
      });
      if(!addedAwayPartnership) return;
      awayPartnership = matchPartnershipInfoOnly(addedAwayPartnership);
    } else {
      awayPartnership = updatedMatch.awayPartnership!;
    }

    const addPropertiesOnly: AddMatchDto = {
      homePartnership,
      awayPartnership,
      sets: [],
      type: updatedMatch.type ?? 'Practice',
      status: 'InProgress',
      date: updatedMatch.date!,
    }
    const validationFailureMessage = validateAddMatchDto(addPropertiesOnly)
    if(validationFailureMessage) {
      enqueueSnackbar(validationFailureMessage, {variant: 'error', autoHideDuration: 5000});
      return;
    }

    setIsFinishing(true);
    try {
      const returnedMatch = await addMatch(addPropertiesOnly);
      props.onSave(returnedMatch);
      return returnedMatch;
    }
    catch (error) {
      enqueueSnackbar('Failed to save match', {variant: 'error', autoHideDuration: 5000});
      console.log('Failed to save match', error);
    }
    finally {
      setIsFinishing(false);
      props.onClose();
    }
  }

  const handleNewHomePlayerOneChange = (player: PlayerInfoDto | null) => {
    setNewHomePlayerOne(player);
  }

  const handleNewHomePlayerTwoChange = (player: PlayerInfoDto | null) => {
    setNewHomePlayerTwo(player);
  }

  const handleNewAwayPlayerOneChange = (player: PlayerInfoDto | null) => {
    setNewAwayPlayerOne(player);
  }

  const handleNewAwayPlayerTwoChange = (player: PlayerInfoDto | null) => {
    setNewAwayPlayerTwo(player);
  }

  const partnershipOptionLabel = (option: MatchPartnershipInfoDto | 'NEW_PARTNERSHIP') => {
    if(option === NEW_PARTNERSHIP) {
      return 'NEW';
    }
    return `${option.playerOne.firstName} ${option.playerOne.lastName} & ${option.playerTwo.firstName} ${option.playerTwo.lastName}`;
  }

  return (
    <Card sx={{ maxWidth: 600, maxHeight: '90vh', mx: 'auto', my: 4, overflowY: 'auto' }}>
      <CardContent>
        <CardHeader title="Match Info" />
        <Stack spacing={2}>
          <FormControl fullWidth>
            <Autocomplete
              options={availableHomePartnerships}
              getOptionLabel={partnershipOptionLabel}
              isOptionEqualToValue={(option, value) => (option as MatchPartnershipInfoDto)?.partnershipId === (value as MatchPartnershipInfoDto)?.partnershipId}
              value={updatedMatch.homePartnership ?? NEW_PARTNERSHIP}
              renderInput={(params) => <TextField {...params} label="Home Partnership" />}
              onChange={(event, newValue) => handlePartnershipChange(true, newValue!)}
            />
          </FormControl>

          {createNewHomePartnership && <Box style={{display: 'flex', gap: '10px', width: '100%', paddingLeft: '20px', paddingRight: '20px'}}>
            <FormControl style={{width: '100%'}}>
              <Autocomplete
                options={availablePlayersAndSelf(newHomePlayerOne, newAwayPlayerTwo?.playerId)}
                getOptionLabel={(option: PlayerInfoDto | null) => !option ? '' : `${option.firstName} ${option.lastName} (${option.school})`}
                isOptionEqualToValue={(option, value) => option?.playerId === value?.playerId}
                value={newHomePlayerOne}
                renderInput={(params) => <TextField {...params} label="Player One" />}
                onChange={(event, newValue) => handleNewHomePlayerOneChange(newValue!)}
              />
            </FormControl>
            <FormControl style={{width: '100%'}}>
              <Autocomplete
                options={availablePlayersAndSelf(newHomePlayerTwo, newHomePlayerOne?.playerId)}
                getOptionLabel={(option: PlayerInfoDto | null) => !option ? '' : `${option.firstName} ${option.lastName} (${option.school})`}
                isOptionEqualToValue={(option, value) => option?.playerId === value?.playerId}
                value={newHomePlayerTwo}
                renderInput={(params) => <TextField {...params} label="Player Two" />}
                onChange={(event, newValue) => handleNewHomePlayerTwoChange(newValue!)}
              />
            </FormControl>
          </Box>}

          <FormControl fullWidth>
            <Autocomplete
              options={availableAwayPartnerships}
              getOptionLabel={partnershipOptionLabel}
              isOptionEqualToValue={(option, value) => (option as MatchPartnershipInfoDto)?.partnershipId === (value as MatchPartnershipInfoDto)?.partnershipId}
              value={updatedMatch.awayPartnership ?? NEW_PARTNERSHIP}
              renderInput={(params: AutocompleteRenderInputParams) => <TextField {...params} label="Away Partnership" />}
              onChange={(event, newValue) => handlePartnershipChange(false, newValue!)}
            />
          </FormControl>

          {createNewAwayPartnership && <Box style={{display: 'flex', gap: '10px', width: '100%', paddingLeft: '20px', paddingRight: '20px'}}>
            <FormControl style={{width: '100%'}}>
              <Autocomplete
                options={availablePlayersAndSelf(newAwayPlayerOne, newAwayPlayerTwo?.playerId)}
                getOptionLabel={(option: PlayerInfoDto | null) => !option ? '' : `${option.firstName} ${option.lastName} (${option.school})`}
                isOptionEqualToValue={(option, value) => option?.playerId === value?.playerId}
                value={newAwayPlayerOne}
                renderInput={(params) => <TextField {...params} label="Player One" />}
                onChange={(event, newValue) => handleNewAwayPlayerOneChange(newValue!)}
              />
            </FormControl>
            <FormControl style={{width: '100%'}}>
              <Autocomplete
                options={availablePlayersAndSelf(newAwayPlayerTwo, newAwayPlayerOne?.playerId)}
                getOptionLabel={(option: PlayerInfoDto | null) => !option ? '' : `${option.firstName} ${option.lastName} (${option.school})`}
                isOptionEqualToValue={(option, value) => option?.playerId === value?.playerId}
                value={newAwayPlayerTwo}
                renderInput={(params) => <TextField {...params} label="Player Two" />}
                onChange={(event, newValue) => handleNewAwayPlayerTwoChange(newValue!)}
              />
            </FormControl>
          </Box>}

          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select value={updatedMatch.type ?? 'Practice'} onChange={handleTypeChange}>
              {['Practice', 'Scrimmage', 'Tournament', 'Other'].map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Match Date */}
          <FormControl fullWidth>
            {/*<DateTimePicker value={(updatedMatch?.date ? dayjs(updatedMatch?.date) : null) as any} onChange={(newValue) => handleDateChange(newValue)} label="Match Date *" />*/}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker value={updatedMatch?.date ? dayjs(updatedMatch?.date) : null} onChange={(newValue) => handleDateChange(newValue)} label="Date/Time *" />
            </LocalizationProvider>
          </FormControl>

          {/* Save Button */}
          <Button onClick={saveAndClose} variant="contained">
            Save Match
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
});

export default MatchInfoComponent;
